<template>
  <div class="section-wrapper">
    <b-card class="trade_fire_wrapper">
      <b-row>
        <div class="sec_title">
          <div class="text">
            <span>{{ currentLocale == 'en' ? 'International Trade Fair' : 'আন্তর্জাতিক বাণিজ্যমেলা'}}</span>
          </div>
          <div class="line"></div>
        </div>
        <b-col sm="4">
          <b-form-group label-for="fiscal_year_id">
            <template v-slot:label>
              {{ $t('tradeFairConfig.fiscal_year') }}
            </template>
            <b-form-select plain id="fiscal_year_id" :options="fiscalYearList" v-model="search.fiscal_year_id">
              <template v-slot:first>
                <b-form-select-option :value=0 disabled>{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="3" class="mt-3">
          <b-button size="sm" variant="primary" class="mb-2" @click="searchData">
            <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
          </b-button>
        </b-col>
      </b-row>
      <ditf-loading v-if="!dataLoad" :step="1" />
      <b-row v-else>
        <b-col class="bdr mt-3 mt-sm-0" sm="4">
          <div class="info-card total" v-b-modal.modal-final>
            <div class="content">
              <p>{{ $t('tradeFairDashboard.total_fair') }}</p>
              <h3>{{ $n(dashboardData.total_fair, { useGrouping: false }) }}</h3>
            </div>
            <img src="@/assets/images/folder-bro.png" class="img-fluid" alt="image">
          </div>
        </b-col>
        <b-col class="bdr" sm="4">
          <div class="info-card upcoming" v-b-modal.modal-upcomming>
            <div class="content">
              <p>{{ $t('tradeFairDashboard.upcoming_fair') }}</p>
              <h3>{{ $n(dashboardData.upcoming_fair, { useGrouping: false }) }}</h3>
            </div>
            <img src="@/assets/images/amico.png" class="img-fluid" alt="image">
          </div>
        </b-col>
        <b-col class="bdr" sm="4">
          <div class="info-card atended" v-b-modal.modal-attended>
            <div class="content">
              <p>{{ $t('tradeFairDashboard.attended_fair2') }}</p>
              <h3>{{ $n(dashboardData.attended_fair, { useGrouping: false }) }}</h3>
            </div>
            <img src="@/assets/images/stall.png" class="img-fluid" alt="image">
          </div>
        </b-col>
        <div class="sec_title">
          <div class="text">
            <span>{{ currentLocale === 'en' ? 'International Trade Fair Calendar' : 'আন্তর্জাতিক বাণিজ্য মেলা ক্যালেন্ডার'}}</span>
          </div>
          <div class="line"></div>
        </div>
        <FairCalendar :scheduleData="selected_date" />
      </b-row>
    </b-card>
    <b-modal id="modal-final" size="lg" :title="currentLocale == 'bn' ? 'মেলা তালিকা' : 'Fair List'" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <ShowFairList :finalList="totalFairList" :fairSectorList="fairSectors"/>
    </b-modal>
    <b-modal id="modal-upcomming" size="lg" :title="currentLocale == 'bn' ? 'আসন্ন মেলা তালিকা' : 'Upcoming Fair List'" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <UpCommingFairList :upCommingList="upCommingList" :attended="false"/>
    </b-modal>
    <b-modal id="modal-attended" size="lg" :title="currentLocale == 'bn' ? 'অংশগ্রহণকৃত মেলা তালিকা' : 'Attended Fair List'" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <UpCommingFairList :upCommingList="attendedList" :attended="true"/>
    </b-modal>
  </div>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import ditfLoading from '@/modules/trade-fair-service/dashboard/loading/ditf_loading'
import FairCalendar from './FairCalendar.vue'
import ShowFairList from './ShowFairList.vue'
import UpCommingFairList from './UpCommingFairList.vue'

export default {
  props: [],
  components: {
    ditfLoading, FairCalendar, ShowFairList, UpCommingFairList
  },
  data () {
    return {
      selected_date: [],
      totalFairList: [],
      fairSectors: [],
      upCommingList: [],
      attendedList: [],
      search: {
          fiscal_year_id: 0
      },
      dashboardData: {
        total_fair: 0,
        upcoming_fair: 0,
        attended_fair: 0
      },
      dataLoad: false
    }
  },
  computed: {
    currentLocale () {
        return this.$i18n.locale
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList
    },
    currentFiscalYearId () {
      return this.$store.state.currentFiscalYearId
    }
  },
  created () {
    this.search.fiscal_year_id = this.currentFiscalYearId
    this.loadData()
  },
  watch: {},
  methods: {
    searchData () {
      this.loadData()
    },
    async loadData () {
        this.dataLoad = false
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const params = Object.assign({}, this.search)
        const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, 'dashboard/itf-ministry-dashboard', params)
        if (result.success) {
            this.dashboardData = result.data
            this.fairSectors = result.data.fairSectors
            this.totalFairList = result.data.totalFairList
            this.upCommingList = result.data.upCommingList
            this.attendedList = result.data.attendedList
            this.selected_date = result.data.schedule_data.map(entry => ({
                                    id: entry.id,
                                    name: entry.fair_name,
                                    name_bn: entry.fair_name_bn,
                                    location: entry.city,
                                    location_bn: entry.city_bn,
                                    country_id: entry.country_id,
                                    startDate: new Date(entry.date_from),
                                    endDate: new Date(entry.date_to)
                                 }))
            this.dataLoad = true
        } else {
          this.dashboardData = {
            total_fair: 0,
            upcoming_fair: 0,
            attended_fair: 0
          }
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>

<style>
.b-calendar .b-calendar-inner .b-calendar-grid .b-calendar-grid-help {
    display: none !important;
}
.b-calendar .b-calendar-inner .b-calendar-nav {
    display: none !important;
}

.trade_fire_wrapper .info-card {
  border-radius: 1rem;
  color: #214162;
  padding: 1rem;
  min-height: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trade_fire_wrapper .info-card .content p {
  font-size: 20px;
}

.trade_fire_wrapper .info-card.upcoming {
  color: #237dd3;
  background: #D2E8FD;
}

.trade_fire_wrapper .info-card.total {
  color: #875c1c;
  background: #FEF5CC;
}
.trade_fire_wrapper .info-card.atended {
  color: #04637e;
  background: #D0F2FC;
}

.trade_fire_wrapper .info-card.remaining {
  color: #f14b1f;
  background: #FEE7D7;
}

.trade_fire_wrapper .info-card img {
  height: 70px;
}
.upcoming-fair{
  background: url(../../../../../assets/images/fair-card-bg.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover !important;
  position: relative;
}
.upcoming-fair .icon img{
  width: 100px;
}
.upcoming-fair .content{
  padding: 2rem;
  width: 100%;
  color: #fff;
  text-align: center;
}
.trade_fire_participant .info-card {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  padding: .5rem;
  border-radius: 1rem;
  background: #fff;
  box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem,
    rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
}
.trade_fire_participant .info-card.applicant .icon{
  background: #48B5E4;
}
.trade_fire_participant .info-card.approved .icon{
  background: #21C228;
}
.trade_fire_participant .info-card.peinding .icon{
  background: #C96BE5;
}
.trade_fire_participant .info-card.reject .icon{
  background: #F8576A;
}
.trade_fire_participant .info-card.paid .icon{
  background: #6349F8;
}
.trade_fire_participant .info-card.unpaid .icon{
  background: #E79B55;
}
.trade_fire_participant .info-card .atended .icon{
  background: #55A6F8;
}
.trade_fire_participant .info-card .icon{
  color: #fff;
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  font-size: 1.5rem;
  margin-right: 0.6rem;
  margin-top: -3rem;
  box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;
  justify-content: center;
  align-items: center;
}
.calendar .calendar-header .year-title {
    font-weight: bold;
    text-align: center;
    height: 20px;
    width: auto;
    color: white;
    background: #233481;
}
</style>
