<template>
    <div>
        <b-overlay :show="loadingOne">
            <b-row class="std-card-list tea-garden-card mt-4">
                <div class="col-sm-6">
                    <div class="card" v-b-modal.modal-tea-garden>
                        <div class="card-content">
                            <p class="title"> {{ currentLocale === 'en' ? 'Total Garden' : 'মোট বাগান' }} </p>
                            <h2 class="count sale_count">{{ $n(resultDataFirst.totalTeaGarden) }}</h2>
                        </div>
                        <div class="icon">
                            <img class="img-fluid" src="@/assets/images/tea-garden/garden.png">
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="card" v-b-modal.modal-botleaf-factory>
                        <div class="card-content">
                            <p class="title"> {{ currentLocale === 'en' ? 'Total Botleaf Factory' : 'মোট বটলিফ কারখানা' }} </p>
                            <h2 class="count sale_count"> {{ $n(resultDataFirst.totalBoughtLeafFactory) }} </h2>
                        </div>
                        <div class="icon">
                            <img class="img-fluid" src="@/assets/images/tea-garden/karkhana.png">
                        </div>
                    </div>
                </div>
            </b-row>
        </b-overlay>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form @submit.prevent="handleSubmit(searchDataTwo)" @reset.prevent="reset" autocomplete="off">
            <b-row class="mt-4 px-4 ml-4 mr-2">
                <b-col sm="5">
                  <ValidationProvider name="Year" vid="year" rules="required|min_value:1">
                    <b-form-group
                      :label="$t('globalTrans.year')"
                      label-for="year"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.year') }} <span class="text-danger">*</span>
                      </template>
                        <b-form-select
                            plain
                            v-model="searchOne.year"
                            :options="yearList"
                            id="year"
                            :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col sm="2">
                    <div class="mt-3">
                        <b-button type="submit" size="sm" variant="secondary">{{ $t('globalTrans.search') }}</b-button>
                    </div>
                </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>

        <b-overlay :show="loadingTwo">
          <b-row class="std-card-list design-2">
              <div class="col-sm-4">
                  <div class="secretary-db-card purple">
                      <div class="icon">
                          <i class="ri-stack-fill"></i>
                      </div>
                      <div class="card-content">
                          <p class="title"> {{ $t('teaGardenDashboard.total_productions') }} </p>
                          <h2 class="count sale_count"> {{ $n(resultDataSecond.totalProduction) }} <span class="text-muted unit">{{ $t('ministry_dashboard.million_kg') }}</span></h2>
                      </div>
                  </div>
              </div>
              <div class="col-sm-4">
                  <div class="secretary-db-card blue">
                      <div class="icon">
                          <i class="ri-shield-check-fill"></i>
                      </div>
                      <div class="card-content">
                          <p class="title"> {{ $t('tradeTariffApp.total_export') }} </p>
                          <h2 class="count sale_count"> {{ $n(resultDataSecond.totalExport) }} <span class="text-muted unit">{{ $t('ministry_dashboard.million_kg') }}</span></h2>
                      </div>
                  </div>
              </div>
              <div class="col-sm-4">
                  <div class="secretary-db-card blue">
                      <div class="icon">
                          <i class="ri-shield-check-fill"></i>
                      </div>
                      <div class="card-content">
                          <p class="title"> {{ $t('tradeTariffApp.total_import') }} </p>
                          <h2 class="count sale_count"> {{ $n(resultDataSecond.totalImport) }} <span class="text-muted unit">{{ $t('ministry_dashboard.million_kg') }}</span></h2>
                      </div>
                  </div>
              </div>
          </b-row>
        </b-overlay>
        <ValidationObserver ref="form1" v-slot="{ handleSubmit, reset }">
          <b-form @submit.prevent="handleSubmit(searchDataThree)" @reset.prevent="reset" autocomplete="off">
            <b-row class="mt-4">
                <b-col sm="3">
                  <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                    <b-form-group
                      :label="$t('globalTrans.fiscal_year')"
                      label-for="fiscal_year_id"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.fiscal_year') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                            plain
                            v-model="searchTwo.fiscal_year_id"
                            :options="fiscalYearList"
                            id="fiscal_year_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col sm="2">
                    <div class="mt-3">
                        <b-button type="submit" size="sm" variant="secondary">{{ $t('globalTrans.search') }}</b-button>
                    </div>
                </b-col>
            </b-row>
            </b-form>
        </ValidationObserver>
        <b-overlay :show="loadingThree">
          <b-row>
              <div class="col-sm-12 mt-3">
                  <b-table-simple small hover bordered responsive head-variant="bg-info">
                    <b-thead class="bg-primary text-white">
                      <b-tr>
                        <b-th class="text-center">{{ $t('ministry_dashboard.tea_auction_center') }}</b-th>
                        <b-th class="text-center">{{ $t('ministry_dashboard.auction_number') }}</b-th>
                        <b-th class="text-center">
                          {{ $t('ministry_dashboard.tea_sold_amount') }} <br>
                          <small>{{ $t('ministry_dashboard.million_kg') }}</small>
                        </b-th>
                        <b-th class="text-center">
                          {{ $t('ministry_dashboard.avg_auction_price') }} <br>
                           <small>{{ $t('ministry_dashboard.taka') }}</small>
                        </b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <template v-if="resultDataThird.length">
                        <b-tr v-for="(item, index) in resultDataThird" :key="index">
                          <b-td class="text-center">{{ getAuctionLocationName(item.auction_location) }}</b-td>
                          <b-td class="text-center">{{ $n(item.total_auction) }}</b-td>
                          <b-td class="text-center">{{ $n(item.total_sale_quantity) }}</b-td>
                          <b-td class="text-center">{{ $n(item.total_average_value) }}</b-td>
                        </b-tr>
                      </template>
                      <template v-else>
                        <b-tr>
                          <b-td align="center" colspan="4">{{ $t('globalTrans.noDataFound') }}</b-td>
                        </b-tr>
                      </template>
                    </b-tbody>
                  </b-table-simple>
              </div>
          </b-row>
        </b-overlay>
        <b-modal id="modal-tea-garden" size="lg" scrollable :title="$t('teaGardenConfig.tea_garden_profile')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <TeaGardenListModal :key="masterTeaCompanyList" :masterTeaCompanyList="masterTeaCompanyList"/>
        </b-modal>
        <b-modal id="modal-botleaf-factory" size="xl" scrollable :title="$t('teaGardenConfig.bought_leaf_factory')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <BotLeafFactoryListModal :key="masterMadeTeaTypeList" :masterMadeTeaTypeList="masterMadeTeaTypeList"/>
        </b-modal>
    </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import TeaGardenListModal from './TeaGardenListModal.vue'
import BotLeafFactoryListModal from './BotLeafFactoryListModal.vue'
export default {
    props: ['yearList'],
    components: { TeaGardenListModal, BotLeafFactoryListModal },
  data () {
    return {
      loadingOne: false,
      loadingTwo: false,
      loadingThree: false,
      searchOne: {
        year: new Date().getFullYear()
      },
      searchTwo: {
        fiscal_year_id: 0
      },
      resultDataFirst: {
        totalTeaGarden: 0,
        totalBoughtLeafFactory: 0
      },
      resultDataSecond: {
        totalProduction: 0,
        totalExport: 0,
        totalImport: 0
      },
      resultDataThird: [],
      masterTeaCompanyList: [],
      masterMadeTeaTypeList: []
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    locationList () {
      return [
        {
          value: 1, text_en: 'Chattogram', text_bn: 'চট্টগ্রাম', text: this.currentLocale === 'en' ? 'Chattogram' : 'চট্টগ্রাম'
        },
        {
          value: 2, text_en: 'Srimongol', text_bn: 'শ্রীমঙ্গল', text: this.currentLocale === 'en' ? 'Srimongol' : 'শ্রীমঙ্গল'
        },
        {
          value: 3, text_en: 'Panchagar', text_bn: 'পঞ্চগড়', text: this.currentLocale === 'en' ? 'Panchagar' : 'পঞ্চগড়'
        }
      ]
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList
    },
    currentFiscalYearId () {
      return this.$store.state.currentFiscalYearId
    }
  },
  async created () {
    this.searchTwo.fiscal_year_id = this.currentFiscalYearId
    await this.loadDropDownList()
  },
  async mounted () {
    await this.searchDataOne()
    await this.searchDataTwo()
    if (this.currentFiscalYearId) {
      await this.searchDataThree()
    }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
            //
        }
    }
  },
  methods: {
    async searchDataOne () {
      this.loadingOne = true
      let result = null
      result = await RestApi.getData(teaGardenServiceBaseUrl, 'ministry-dashboard/total-info')
      if (result.success) {
        this.resultDataFirst = Object.assign({}, this.resultDataFirst, result.data)
      }
      this.loadingOne = false
    },
    async searchDataTwo () {
      this.loadingTwo = true
      let result = null
      const params = Object.assign({}, this.searchOne)
      result = await RestApi.getData(teaGardenServiceBaseUrl, 'ministry-dashboard/production-info', params)
      if (result.success) {
        this.resultDataSecond = Object.assign({}, this.resultDataSecond, result.data)
      }
      this.loadingTwo = false
    },
    async searchDataThree () {
      this.loadingThree = true
      let result = null
      const params = Object.assign({}, this.searchTwo)
      result = await RestApi.getData(teaGardenServiceBaseUrl, 'ministry-dashboard/auction-info', params)
      if (result.success) {
        this.resultDataThird = result.data
      }
      this.loadingThree = false
    },
    getAuctionLocationName (id) {
      const obj = this.locationList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    async loadDropDownList () {
      let result = null
      result = await RestApi.getData(teaGardenServiceBaseUrl, 'ministry-common-dropdowns')
      if (result.success) {
        this.masterTeaCompanyList = result.data.masterTeaCompanyList
        this.masterMadeTeaTypeList = result.data.masterMadeTeaTypeList
      }
    }
  }
}
</script>
<style>
  .mc-report-card {
    position: relative;
    margin-bottom: 10px;
    border-radius: var(--border-radius-md);
  }
  .icon-wrapper {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translate(0, -50%);
    color: rgba(255, 255, 255, .3);
    font-size: 3.6rem;
  }
  .sale_count .unit {
    font-size: 1rem;
  }
</style>
