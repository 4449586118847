<template>
  <b-overlay :show="loading">
    <MarqueeText :item="item" :retailScroll="retailScroll" :retailPriceDate="last_collection_date" :wholeScroll="wholeSaleScroll" :wholeSalePriceDate="last_collection_dateWS" />

    <b-overlay :show="cardLoading">
      <template>
        <div>
          <b-container>
            <b-row>
              <b-col
                  cols="12"
                  class="mb-3"
                  v-for="(item, index) in cardPriceList"
                  :key="index"
                  md="3"
                >
                  <CardComp :item="item" :backgroundImage="backgroundImageCheck(item)"></CardComp>
                </b-col>
            </b-row>
          </b-container>
        </div>
      </template>
    </b-overlay>

    <b-overlay :show="bazarDorLoading">
      <body-card>
        <!-- search section start -->
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('ministry_dashboard.today_market_rate') }}</h4>
        </template>
        <div class="p-3">
          <b-row>
              <b-col lg="12" sm="12" class="table-responsive">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                          <ValidationProvider name="Area Type" vid="area_type_id">
                              <b-form-group
                                  label-for="area_type_id"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('bazarMonitoring.area_type')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.area_type_id"
                                  :options="areaTypeList"
                                  id="area_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                  <template v-slot:first>
                                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="search.area_type_id === 1 || search.area_type_id === 2 || search.area_type_id === 3">
                          <ValidationProvider name="Division" vid="division_id">
                              <b-form-group
                                  label-for="division_id"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('globalTrans.division')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.division_id"
                                  :options="divisionList"
                                  id="division_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                  <template v-slot:first>
                                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="search.area_type_id === 1 || search.area_type_id === 2 || search.area_type_id === 3">
                          <ValidationProvider name="District" vid="district_id">
                              <b-form-group
                                  label-for="district_id"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('globalTrans.district')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.district_id"
                                  :options="districtList"
                                  id="district_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                  <template v-slot:first>
                                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="search.area_type_id === 1">
                          <ValidationProvider name="City Corporation" vid="city_corporation_id">
                              <b-form-group
                                  label-for="city_corporation_id"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('globalTrans.city_corporation')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.city_corporation_id"
                                  :options="corporationList"
                                  id="city_corporation_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                  <template v-slot:first>
                                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="search.area_type_id === 2 || search.area_type_id === 3">
                          <ValidationProvider name="Upazila" vid="upazila_id">
                              <b-form-group
                                  label-for="upazila_id"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('globalTrans.upazila')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.upazila_id"
                                  :options="upazilaList"
                                  id="upazila_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                  <template v-slot:first>
                                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="search.area_type_id === 2">
                          <ValidationProvider name="Pauroshoba" vid="pauroshoba_id">
                              <b-form-group
                                  label-for="pauroshoba_id"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('globalTrans.pouroshova')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.pauroshoba_id"
                                  :options="pauroshobaList"
                                  id="pauroshoba_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                  <template v-slot:first>
                                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="search.area_type_id === 3">
                          <ValidationProvider name="Union" vid="union_id">
                              <b-form-group
                                  label-for="union_id"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('globalTrans.union')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.union_id"
                                  :options="unionList"
                                  id="union_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                  <template v-slot:first>
                                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <ValidationProvider name="0" vid="market_directory_id">
                                <b-form-group
                                    label-for="market_directory_id"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('bazarMonitoring.market_name')}}
                                </template>
                                <b-form-select
                                    plain
                                    v-model="search.market_directory_id"
                                    :options="marketDirectoryList"
                                    id="market_directory_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                    <template v-slot:first>
                                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                          <b-form-group
                          label-for="price_collection_date"
                          >
                          <template v-slot:label>
                              {{ $t('globalTrans.date') }}<span class="text-danger">*</span>
                          </template>
                            <date-picker
                              id="price_collection_date"
                              v-model="search.price_collection_date"
                              class="form-control"
                              :placeholder="$t('globalTrans.select')"
                              :locale="currentLocale"
                            >
                            </date-picker>
                          </b-form-group>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-button  type="submit" size="sm" variant="primary float-right">
                            <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                            </b-button>
                        </b-col>
                    </b-row>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
        </div>
        <!-- search section end -->
      </body-card>
      <template>
        <div class="text-black">
            <b-row>
              <b-col>
                <strong>{{ $t('globalTrans.division') }}: </strong>{{ search.division_id ? ($i18n.locale === 'en' ? searchHeaderData.division_name : searchHeaderData.division_name_bn) : $t('globalTrans.all')  }}
              </b-col>
              <b-col>
                <strong>{{ $t('globalTrans.district') }}: </strong>{{ search.district_id  ?  ($i18n.locale === 'en' ? searchHeaderData.district_name : searchHeaderData.district_name_bn) : $t('globalTrans.all') }}
              </b-col>
                <b-col>
                <strong>{{ $t('bazarMonitoring.market_name') }}: </strong>{{ search.market_directory_id ? ($i18n.locale === 'en' ? searchHeaderData.market_name_en : searchHeaderData.market_name_bn) : $t('globalTrans.all') }}
              </b-col>
              <b-col>
                <strong>{{ $t('report.date') }}: </strong>{{ search.price_collection_date | dateFormat }}
              </b-col>
            </b-row>
            <b-row>
              <b-col v-if="search.area_type_id === 1">
                <strong>{{ $t('globalTrans.city_corporation') }}: </strong>{{ search.city_corporation_id ? ($i18n.locale === 'en' ? searchHeaderData.city_corporation : searchHeaderData.city_corporation_bn) : $t('globalTrans.all')  }}
              </b-col>
              <b-col v-if="search.area_type_id === 2">
                <strong>{{ $t('globalTrans.pouroshova') }}: </strong>{{ search.pauroshoba_id ? ($i18n.locale === 'en' ? searchHeaderData.pauroshoba_name : searchHeaderData.pauroshoba_name_bn) : $t('globalTrans.all')  }}
              </b-col>
                <b-col v-if="search.area_type_id === 2 || search.area_type_id === 3">
                <strong>{{ $t('globalTrans.upazila') }}: </strong>{{ search.upazila_id  ?  ($i18n.locale === 'en' ? searchHeaderData.upazila_name : searchHeaderData.upazila_name_bn) : $t('globalTrans.all') }}
              </b-col>
              <b-col v-if="search.area_type_id === 3">
                <strong>{{ $t('globalTrans.union') }}: </strong>{{ search.union_id ? ($i18n.locale === 'en' ? searchHeaderData.union_name : searchHeaderData.union_name_bn) : $t('globalTrans.all') }}
              </b-col>
            </b-row>
          </div>
        <b-row >
          <b-col>
            <b-table-simple sticky-header class="tg mt-3" bordered striped hover small caption-top responsive :emptyText="$t('globalTrans.noDataFound')">
              <b-thead>
                <b-tr>
                  <!-- <b-th stickyColumn rowspan="3" style="width:7%" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                  <b-th stickyColumn rowspan="3" style="width:13%" class="text-center">{{ $t('bazarMonitoring.commodity_group_name') }}</b-th> -->
                  <b-th stickyColumn rowspan="3" style="width:12%" class="text-center">{{ $t('bazarMonitoring.commodity_name') }}</b-th>
                  <b-th stickyColumn rowspan="3" style="width:12%" class="text-center">{{ $t('bazarMonitoring.measurement_unit') }}</b-th>
                  <b-th stickyColumn :colspan="priceCategoryArr.length*4" style="width:20%" class="text-center z-index-5">{{ $t('report.price') }}</b-th>
                </b-tr>
                <b-tr>
                    <b-th class="top-38 z-index-4" stickyColumn v-for="(priceCategory, index) in priceCategoryArrNew" :colspan="4" :key="index" style="text-align: center;vertical-align: middle;">{{ ($i18n.locale === 'bn') ? priceCategory.price_category_bn : priceCategory.price_category }}</b-th>
                </b-tr>
                <b-tr>
                  <template v-for="(priceCategory, index) in priceCategoryArrNew">
                    <b-th stickyColumn :key="index+1000" class="z-index-3" :class="currentLocale === 'bn' ? 'top-80' : 'top-95'"  style="text-align: center;vertical-align: middle;">{{ $t('ministry_dashboard.retailer_title') }}</b-th>
                    <!-- <b-th stickyColumn :key="index+2000" class="z-index-3" :class="currentLocale === 'bn' ? 'top-80' : 'top-95'"  style="text-align: center;vertical-align: middle;">{{ $t('report.h') }}</b-th> -->
                    <!-- <b-th stickyColumn :key="index+3000" class="z-index-3" :class="currentLocale === 'bn' ? 'top-80' : 'top-95'"  style="text-align: center;vertical-align: middle;">{{ $t('report.tk') }}</b-th>
                    <b-th stickyColumn :key="index+4000" class="z-index-3" :class="currentLocale === 'bn' ? 'top-80' : 'top-95'"  style="text-align: center;vertical-align: middle;">{{ $t('report.%') }}</b-th> -->
                  </template>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-for="(info, index) in datas">
                  <!-- <b-tr :key="index">
                    <b-td stickyColumn :rowspan="info.commodity_name_info.length + 1" class="text-center first-column-1">{{ $n(index + 1) }}</b-td>
                    <b-td stickyColumn :rowspan="info.commodity_name_info.length + 1" class="text-center second-column-1">{{ ($i18n.locale === 'bn') ? info.commodity_group_bn : info.commodity_group }}</b-td>
                  </b-tr> -->
                  <template v-for="(info1, index2) in info.commodity_name_info">
                    <b-tr :key="index + '_' + index2">
                      <b-td stickyColumn class="text-center">{{ ($i18n.locale === 'bn') ? info1.commodity_name_bn : info1.commodity_name }}</b-td>
                      <b-td stickyColumn class="text-center">{{ ($i18n.locale === 'bn') ? info1.unit_name_bn : info1.unit_name }}</b-td>
                      <template v-for="(priceCategory, index3) in priceCategoryArrNew">
                        <slot>
                        <b-td class="text-center" :key="index + '_' +index3+1000">
                          {{ getPriceCategoryInfo(priceCategory.value, info1.price_category_info) !== undefined ? $n(getPriceCategoryInfo(priceCategory.value, info1.price_category_info).highest_price_amount): ($i18n.locale == 'bn') ? '০' : '0' }} -
                          {{ getPriceCategoryInfo(priceCategory.value, info1.price_category_info) !== undefined ? $n(getPriceCategoryInfo(priceCategory.value, info1.price_category_info).lowest_price_amount): ($i18n.locale == 'bn') ? '০' : '0' }}
                        </b-td>
                        <!-- <b-td class="text-center" :key="index + '_' +index3+2000">
                          {{ getPriceCategoryInfo(priceCategory.value, info1.price_category_info) !== undefined ? $n(getPriceCategoryInfo(priceCategory.value, info1.price_category_info).highest_price_amount): ($i18n.locale == 'bn') ? '০' : '0' }}
                        </b-td> -->
                        <!-- <b-td class="text-center" :key="index + '_' +index3+3000">
                          {{ getPriceCategoryInfo(priceCategory.value, info1.price_category_info) !== undefined ? $n(getPriceCategoryInfo(priceCategory.value, info1.price_category_info).lowest_price_percentage): ($i18n.locale == 'bn') ? '০' : '0' }}
                          </b-td> -->
                        <!-- <b-td class="text-center" :key="index + '_' +index3+4000">
                          {{ getPriceCategoryInfo(priceCategory.value, info1.price_category_info) !== undefined ? $n(getPriceCategoryInfo(priceCategory.value, info1.price_category_info).highest_price_percentage): ($i18n.locale == 'bn') ? '০' : '0' }}
                        </b-td> -->
                          <!-- <b-td class="text-center" style="font-size: 12px;width:150px !important; padding: 5px 0px 5px 0px">
                          <slot v-if="info1.price_category_info">
                            <slot v-if="getPriceCategoryInfo(priceCategory.value, info1.price_category_info) !== undefined">
                              <slot v-if="getPriceCategoryInfo(priceCategory.value, info1.price_category_info).lowest_price_percentage < 0">
                              <span style="color: var(--success)">▼ {{ $n(getPriceCategoryInfo(priceCategory.value, info1.price_category_info).lowest_price_percentage) }}</span>
                              </slot>
                              <slot v-else-if="getPriceCategoryInfo(priceCategory.value, info1.price_category_info).lowest_price_percentage > 0">
                                <span style="color: #FF0000;">▲ {{ $n(getPriceCategoryInfo(priceCategory.value, info1.price_category_info).lowest_price_percentage) }}</span>
                              </slot>
                              <slot v-else>
                                {{$n(0.00.toFixed(2))}}
                              </slot>
                            </slot>
                            <slot v-else><span>{{$n(0.00.toFixed(2))}}</span></slot>
                          </slot>
                          <slot v-else><span>{{$n(0.00.toFixed(2))}}</span></slot>
                        </b-td> -->
                        <!-- <b-td class="text-center" style="font-size: 12px;width:150px !important; padding: 5px 0px 5px 0px">
                          <slot v-if="info1.price_category_info">
                            <slot v-if="getPriceCategoryInfo(priceCategory.value, info1.price_category_info) !== undefined">
                              <slot v-if="getPriceCategoryInfo(priceCategory.value, info1.price_category_info).highest_price_percentage < 0">
                                <span style="color: var(--success)">▼ {{ $n(getPriceCategoryInfo(priceCategory.value, info1.price_category_info).highest_price_percentage) }}</span>
                              </slot>
                              <slot v-else-if="getPriceCategoryInfo(priceCategory.value, info1.price_category_info).highest_price_percentage > 0">
                                <span style="color: #FF0000;">▲ {{ $n(getPriceCategoryInfo(priceCategory.value, info1.price_category_info).highest_price_percentage) }}</span>
                              </slot>
                              <slot v-else><span>{{$n(0.00.toFixed(2))}}</span></slot>
                            </slot>
                            <slot v-else><span>{{$n(0.00.toFixed(2))}}</span></slot>
                          </slot>
                          <slot v-else><span>{{$n(0.00.toFixed(2))}}</span></slot>
                        </b-td> -->
                        </slot>
                      </template>
                      </b-tr>
                  </template>
                </template>
                  <template v-if="datas.length === 0">
                  <tr>
                    <th colspan="7" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                  </tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </b-col>
          <!-- <pre>{{excelFieldsOther}}</pre>
          <pre>{{priceCategoryArr}}</pre> -->
        </b-row>
      </template>
    </b-overlay>

    <b-overlay :show="reportLoading">
      <body-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('ministry_dashboard.product_wise_overall_picture') }}</h4>
        </template>
        <div class="p-3">
          <b-row>
            <b-col lg="12" sm="12">
              <ValidationObserver ref="form2"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(loadData)" @reset.prevent="reset" >
                  <b-row>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <ValidationProvider name="Area Type" vid="area_type_id">
                          <b-form-group
                              label-for="area_type_id"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('bazarMonitoring.area_type')}}
                          </template>
                          <b-form-select
                              plain
                              v-model="priceAvgSearch.area_type_id"
                              :options="areaTypeListAvg"
                              id="area_type_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                              <template v-slot:first>
                              <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                          </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="priceAvgSearch.area_type_id === 1 || priceAvgSearch.area_type_id === 2 || priceAvgSearch.area_type_id === 3">
                      <ValidationProvider name="Division" vid="division_id">
                          <b-form-group
                              label-for="division_id"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('globalTrans.division')}}
                          </template>
                          <b-form-select
                              plain
                              v-model="priceAvgSearch.division_id"
                              :options="divisionListAvg"
                              id="division_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                              <template v-slot:first>
                              <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                          </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="priceAvgSearch.area_type_id === 1 || priceAvgSearch.area_type_id === 2 || priceAvgSearch.area_type_id === 3">
                      <ValidationProvider name="District" vid="district_id">
                          <b-form-group
                              label-for="district_id"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('globalTrans.district')}}
                          </template>
                          <b-form-select
                              plain
                              v-model="priceAvgSearch.district_id"
                              :options="districtListAvg"
                              id="district_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                              <template v-slot:first>
                              <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                          </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="priceAvgSearch.area_type_id === 1">
                      <ValidationProvider name="City Corporation" vid="city_corporation_id_avg">
                          <b-form-group
                              label-for="city_corporation_id_avg"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('globalTrans.city_corporation')}}
                          </template>
                          <b-form-select
                              plain
                              v-model="priceAvgSearch.city_corporation_id"
                              :options="corporationListAvg"
                              id="city_corporation_id_avg"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                              <template v-slot:first>
                              <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                          </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="priceAvgSearch.area_type_id === 2 || priceAvgSearch.area_type_id === 3">
                      <ValidationProvider name="Upazila" vid="upazila_id">
                          <b-form-group
                              label-for="upazila_id"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('globalTrans.upazila')}}
                          </template>
                          <b-form-select
                              plain
                              v-model="priceAvgSearch.upazila_id"
                              :options="upazilaListAvg"
                              id="upazila_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                              <template v-slot:first>
                              <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                          </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="priceAvgSearch.area_type_id === 2">
                      <ValidationProvider name="Pauroshoba" vid="pauroshoba_id">
                          <b-form-group
                              label-for="pauroshoba_id"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('globalTrans.pouroshova')}}
                          </template>
                          <b-form-select
                              plain
                              v-model="priceAvgSearch.pauroshoba_id"
                              :options="pauroshobaListAvg"
                              id="pauroshoba_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                              <template v-slot:first>
                              <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                          </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="priceAvgSearch.area_type_id === 3">
                      <ValidationProvider name="Union" vid="union_id">
                          <b-form-group
                              label-for="union_id"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('globalTrans.union')}}
                          </template>
                          <b-form-select
                              plain
                              v-model="priceAvgSearch.union_id"
                              :options="unionListAvg"
                              id="union_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                              <template v-slot:first>
                              <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                          </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <ValidationProvider name="0" vid="market_directory_id">
                            <b-form-group
                                label-for="market_directory_id"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('bazarMonitoring.market_name')}}
                            </template>
                            <b-form-select
                                plain
                                v-model="priceAvgSearch.market_directory_id"
                                :options="marketDirectoryListAvg"
                                id="market_directory_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                                <template v-slot:first>
                                <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <b-form-group
                      label-for="price_collection_date"
                      >
                      <template v-slot:label>
                          {{ $t('globalTrans.date') }}<span class="text-danger">*</span>
                      </template>
                        <date-picker
                          id="price_collection_date"
                          v-model="priceAvgSearch.price_collection_date"
                          class="form-control"
                          :placeholder="$t('globalTrans.select')"
                          :locale="currentLocale"
                        >
                        </date-picker>
                      </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                        <b-button  @click="loadData()" size="sm" variant="primary float-right">
                        <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                        </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </ValidationObserver>
            </b-col>
          </b-row>
        </div>
      </body-card>
      <template>
        <div class="text-black">
          <b-row>
            <b-col md="3" lg="3">
              <span>{{ $t('globalTrans.division') }}: </span><strong>{{ priceAvgSearch.division_id ? ($i18n.locale === 'en' ? priceAvgSearchHeaderData.division_name : priceAvgSearchHeaderData.division_name_bn) : $t('globalTrans.all') }}</strong>
            </b-col>
            <b-col md="3" lg="3">
              <span>{{ $t('globalTrans.district') }}: </span><strong>{{ priceAvgSearch.district_id  ?  ($i18n.locale === 'en' ? priceAvgSearchHeaderData.district_name : priceAvgSearchHeaderData.district_name_bn) : $t('globalTrans.all') }}</strong>
            </b-col>
            <template v-if="priceAvgSearch.area_type_id === 1">
              <b-col md="3" lg="3">
                <span>{{ $t('globalTrans.city_corporation') }}: </span><strong>{{ priceAvgSearch.city_corporation_id  ?  ($i18n.locale === 'en' ? priceAvgSearchHeaderData.city_corporation_name : priceAvgSearchHeaderData.city_corporation_name_bn) : $t('globalTrans.all') }}</strong>
              </b-col>
            </template>
            <template v-else>
              <b-col md="3" lg="3">
                <span>{{ $t('globalTrans.upazila') }}: </span><strong>{{ priceAvgSearch.upazila_id  ?  ($i18n.locale === 'en' ? priceAvgSearchHeaderData.upazila_name : priceAvgSearchHeaderData.upazila_name_bn) : $t('globalTrans.all') }}</strong>
              </b-col>
              <b-col md="3" lg="3" v-if="priceAvgSearch.area_type_id === 2">
                <span>{{ $t('globalTrans.pouroshova') }}: </span><strong>{{ priceAvgSearch.pauroshoba_id ? ($i18n.locale === 'en' ? priceAvgSearchHeaderData.pauroshoba_name : priceAvgSearchHeaderData.pauroshoba_name_bn) : $t('globalTrans.all') }}</strong>
              </b-col>
              <b-col md="3" lg="3" v-else>
                <span>{{ $t('globalTrans.union') }}: </span><strong>{{ priceAvgSearch.union_id ? ($i18n.locale === 'en' ? priceAvgSearchHeaderData.union_name : priceAvgSearchHeaderData.union_name_bn) : $t('globalTrans.all') }}</strong>
              </b-col>
            </template>
          </b-row>
          <b-row class="mt-3">
            <b-col md="3" lg="3">
              <span>{{ $t('bazarMonitoring.market_name') }}: </span><strong>{{ priceAvgSearch.market_directory_id ? ($i18n.locale === 'en' ? priceAvgSearchHeaderData.market_name_en : priceAvgSearchHeaderData.market_name_bn) : $t('globalTrans.all') }}</strong>
            </b-col>
            <!-- <b-col>
              <span>{{ $t('report.frequency_type') }}: </span><strong>{{ priceAvgSearch.frequency_type.length ? ($i18n.locale === 'en' ? priceAvgSearchHeaderData.frequency_name : priceAvgSearchHeaderData.frequency_name_bn) : $t('globalTrans.all') }}</strong>
            </b-col> -->
            <!-- <b-col>
              <span>{{ $t('bazarMonitoring.Price_category') }}: </span><strong>{{ priceAvgSearch.price_category_id  ?  ($i18n.locale === 'en' ? priceAvgSearchHeaderData.price_category_name : priceAvgSearchHeaderData.price_category_name_bn) : $t('globalTrans.all') }}</strong>
            </b-col> -->
            <b-col md="3" lg="3">
              <span>{{ $t('globalTrans.date') }}: </span><strong>{{ priceAvgSearch.price_collection_date|dateFormat }}</strong>
            </b-col>
          </b-row>
          <!-- <b-row class="mt-3">
            <b-col md="3" lg="3">
              <span>{{ $t('bazarMonitoring.commodity_group') }}: </span><strong>{{ priceAvgSearch.commodity_group_id.length ? ($i18n.locale === 'en' ? priceAvgSearchHeaderData.commodity_group_name_en : priceAvgSearchHeaderData.commodity_group_name_bn) : $t('globalTrans.all') }}</strong>
            </b-col>
            <b-col md="3" lg="3">
              <span>{{ $t('bazarMonitoring.commodity_name') }}: </span><strong>{{ priceAvgSearch.commodity_name_id.length ? ($i18n.locale === 'en' ? priceAvgSearchHeaderData.commodity_name_en : priceAvgSearchHeaderData.commodity_name_bn) : $t('globalTrans.all') }}</strong>
            </b-col>
          </b-row> -->
        </div>
        <b-row >
          <b-col>
            <b-table-simple sticky-header class="tg mt-3" bordered striped hover small caption-top responsive :emptyText="$t('globalTrans.noDataFound')">
              <b-thead>
                <b-tr>
                  <!-- <b-th stickyColumn rowspan="2" style="width:7%" class="text-center first-column">{{ $t('globalTrans.sl_no') }}</b-th>
                  <b-th stickyColumn rowspan="2" style="width:13%" class="text-center second-column">{{ $t('bazarMonitoring.commodity_group') }}</b-th> -->
                  <b-th stickyColumn rowspan="2" style="width:13%" class="text-center">{{ $t('bazarMonitoring.commodity_name') }}</b-th>
                  <!-- <b-th stickyColumn rowspan="2" style="width:13%" class="text-center fourth-column">{{ $t('bazarMonitoring.Price_category') }}</b-th> -->
                  <template v-for="(info, index) in frequencyDateArr">
                    <b-th stickyColumn :key="index" style="width:20%" class="text-center rightzindex">
                      {{ currentLocale === 'en' ? info.frequency_text_en : info.frequency_text_bn }} {{ info.frequency_date | dateFormat }}
                    </b-th>
                  </template>
                </b-tr>
                <b-tr>
                  <template v-for="(info, index) in frequencyDateArr">
                    <b-th stickyColumn class="text-center underzindex" :class="currentLocale === 'bn' ? 'top-80' : 'top-58'" :key="'L' + index">{{ $t('ministry_dashboard.retailer_title') }}</b-th>
                    <!-- <b-th stickyColumn class="text-center underzindex" :class="currentLocale === 'bn' ? 'top-75' : 'top-58'" :key="'F' + index">{{ $t('report.h') }}</b-th>
                    <b-th stickyColumn class="text-center underzindex" :class="currentLocale === 'bn' ? 'top-75' : 'top-58'" :key="'tk' + index"><i class="text-success ri-arrow-down-line"></i><i class="text-danger ri-arrow-up-line"></i>{{ $t('report.tk') }}</b-th>
                    <b-th stickyColumn class="text-center underzindex" :class="currentLocale === 'bn' ? 'top-75' : 'top-58'" :key="'%' + index"><i class="text-success ri-arrow-down-line"></i><i class="text-danger ri-arrow-up-line"></i>{{ $t('report.%') }}</b-th> -->
                  </template>
                </b-tr>
              </b-thead>
              <!-- <pre>
                {{listData}}
              </pre> -->
              <b-tbody>
                <template v-if="listData.length">
                  <template v-for="(item, index) in listData">
                    <template v-for="(commodityNameInfo, commodityNameInfoIndex) in item.commodity_name_info">
                      <template v-for="(priceCategoryInfo, priceCategoryInfoIndex) in commodityNameInfo.price_category_info">
                        <b-tr :key="'I-' + index + '-N-' + commodityNameInfoIndex + '-C-' + priceCategoryInfoIndex">
                          <!-- <slot v-if="commodityNameInfoIndex === 0 && priceCategoryInfoIndex === 0">
                            <b-td stickyColumn class="text-center first-column" :rowspan="(item.total_rows > 1 ? item.total_rows : null)">{{ $n(index + 1) }}</b-td>
                            <b-td stickyColumn class="text-center second-column" :rowspan="(item.total_rows > 1 ? item.total_rows : null)">{{ ($i18n.locale === 'en') ? item.commodity_group_name_en : item.commodity_group_name_bn }}</b-td>
                          </slot> -->
                          <b-td stickyColumn v-if="priceCategoryInfoIndex === 0" class="text-center" :rowspan="(commodityNameInfo.price_category_info.length > 1 ? commodityNameInfo.price_category_info.length : null)">{{ ($i18n.locale === 'en') ? commodityNameInfo.commodity_name_en : commodityNameInfo.commodity_name_bn }}</b-td>
                          <!-- <b-td stickyColumn class="text-center fourth-column">{{ ($i18n.locale === 'en') ? priceCategoryInfo.price_category_name_en : priceCategoryInfo.price_category_name_bn }}</b-td> -->
                          <!-- {{ priceCategoryInfo }} -->
                          <template v-if="priceCategoryInfo.price_category_id === 5">
                            <template v-for="(frequencyItem, frequencyIndex) in priceCategoryInfo.frequency_type_info">
                              <b-td :key="'I-' + index + '-N-' + commodityNameInfoIndex + '-C-' + priceCategoryInfoIndex + '-f-1-'+frequencyIndex" class="text-center">
                                {{ $n(frequencyItem.lowest_price_amount) }} - {{ $n(frequencyItem.highest_price_amount) }}</b-td>
                            </template>
                          </template>
                        </b-tr>
                      </template>
                    </template>
                  </template>
                </template>
                <template v-else>
                  <b-tr>
                    <b-td class="text-center" :colspan="4 + (4 * frequencyDateArr.length)">{{ $t('globalTrans.noDataFound') }}</b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
      </template>
    </b-overlay>
  </b-overlay>
</template>
<script>
import RestApi, { bazarMonitoringServiceBaseUrl } from '@/config/api_config'
import ModalBaseMasterList from '@/mixins/list'
import bazarMonitoringServiceMixin from '@/mixins/bazar-monitoring-service'
import CardComp from '@/components/CardComp.vue'
import MarqueeText from './components/BazarDorMarqueeText.vue'

const today = new Date().toISOString().substr(0, 10)
// const today = '2023-04-17'

export default {
  mixins: [ModalBaseMasterList, bazarMonitoringServiceMixin],
  components: { CardComp, MarqueeText },
  data () {
    return {
      loading: false,
      sortBy: '',
      search: {
        area_type_id: 0,
        city_corporation_id: 0,
        pauroshoba_id: 0,
        division_id: 0,
        district_id: 0,
        upazila_id: 0,
        union_id: 0,
        market_directory_id: 0,
        price_collection_date: today
      },
      searchHeaderData: {
        area_type: '',
        area_type_bn: '',
        division_name: '',
        division_name_bn: '',
        district_name: '',
        district_name_bn: '',
        city_corporation: '',
        city_corporation_bn: '',
        upazila_name: '',
        upazila_name_bn: '',
        union_name: '',
        union_name_bn: '',
        market_name_en: '',
        market_name_bn: '',
        pauroshoba_name: '',
        pauroshoba_name_bn: '',
        price_collection_date: ''
      },
      editItemId: '',
      officer: {},
      sortDesc: true,
      sortDirection: 'desc',
      marketDirectoryList: [],
      districtList: [],
      upazilaList: [],
      corporationList: [],
      unionList: [],
      wardList: [],
      pauroshobaList: [],
      datas: [],
      userList: [],
      showData: false,
      priceCategoryArr: [],
      priceCategoryArrNew: [],
      priceAvgSearch: {
        commodity_group_id: [],
        commodity_name_id: [],
        market_directory_id: 0,
        frequency_type: [1, 2, 3, 4, 5, 6],
        frequency_type_check: [],
        price_category_id: 0,
        area_type_id: 0,
        city_corporation_id: 0,
        pauroshoba_id: 0,
        division_id: 0,
        district_id: 0,
        upazila_id: 0,
        union_id: 0,
        price_collection_date: today,
        limit: 20,
        action: ''
      },
      priceAvgSearchHeaderData: {
        price_category_name: '',
        price_category_name_bn: '',
        frequency_name: '',
        frequency_name_bn: '',
        division_name: '',
        division_name_bn: '',
        district_name: '',
        district_name_bn: '',
        city_corporation_name: '',
        city_corporation_name_bn: '',
        upazila_name: '',
        upazila_name_bn: '',
        union_name: '',
        union_name_bn: '',
        pauroshoba_name: '',
        pauroshoba_name_bn: '',
        market_name_en: '',
        market_name_bn: '',
        commodity_group_name_en: '',
        commodity_group_name_bn: '',
        commodity_name_en: '',
        commodity_name_bn: '',
        price_collection_date: ''
      },
      frequencyDateArr: [],
      listData: [],
      frequencyTypeList2:
      [
        {
          value: 1,
          text: 'Daily',
          text_en: 'Daily',
          text_bn: 'দৈনিক'
        },
        {
          value: 2,
          text: 'Weekly',
          text_en: 'Weekly',
          text_bn: 'সাপ্তাহিক'
        },
        {
          value: 3,
          text: 'Monthly',
          text_en: 'Monthly',
          text_bn: 'মাসিক'
        },
        {
          value: 4,
          text: 'Quarterly',
          text_en: 'Quarterly',
          text_bn: 'ত্রৈমাসিক'
        },
        {
          value: 5,
          text: 'Half Yearly',
          text_en: 'Half Yearly',
          text_bn: 'ষাণ্মাসিক'
        },
        {
          value: 6,
          text: 'Yearly',
          text_en: 'Yearly',
          text_bn: 'বার্ষিক'
        }
      ],
      marketDirectoryListAvg: [],
      districtListAvg: [],
      upazilaListAvg: [],
      corporationListAvg: [],
      unionListAvg: [],
      wardListAvg: [],
      pauroshobaListAvg: [],
      cardPriceList: [],
      backgroundImage1: require('@/assets/imgs/card-bg-1.svg'),
      backgroundImage2: require('@/assets/imgs/card-bg-2.svg'),
      backgroundImage3: require('@/assets/imgs/card-bg-3.svg'),
      backgroundImage4: require('@/assets/imgs/card-bg-4.svg'),
      backgroundImage5: require('@/assets/imgs/card-bg-5.svg'),
      second_last_collection_date: '',
      last_collection_date: '',
      second_last_collection_dateWS: '',
      last_collection_dateWS: '',
      retailScroll: [],
      wholeSaleScroll: [],
      items: [
        {
          isActive: true,
          age: 40,
          first_name: 'Dickerson',
          last_name: 'Macdonald'
        },
        { isActive: false, age: 21, first_name: 'Larsen', last_name: 'Shaw' },
        { isActive: false, age: 89, first_name: 'Geneva', last_name: 'Wilson' },
        { isActive: true, age: 38, first_name: 'Jami', last_name: 'Carney' }
      ],
      cardLoading: false,
      reportLoading: false,
      bazarDorLoading: false,
      marketDirectoryListOriginal: [],
      marketDirectoryListAvgOriginal: []
    }
  },
  created () {
    this.cardPrice()
    this.loadDropdown()
  },
  watch: {
    'search.area_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.marketDirectoryList = this.getAreaMarketList(newVal)
      } else {
        this.marketDirectoryList = []
      }
    },
    'search.division_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.districtList = this.getDistrictList(newVal)
        this.marketDirectoryList = this.getDivMarketList(newVal)
      } else {
        this.districtList = []
        this.marketDirectoryList = []
      }
    },
    'search.district_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.upazilaList = this.getUpazilaList(newVal)
        this.corporationList = this.getcityCorporationList(newVal)
        this.marketDirectoryList = this.getDisMarketList(newVal)
      } else {
        this.upazilaList = []
        this.corporationList = []
        this.marketDirectoryList = []
      }
    },
    'search.upazila_id': function (newVal, oldVal) {
        if (newVal) {
          this.unionList = this.getUnionList(newVal)
          this.pauroshobaList = this.getPauroshobaList(newVal)
          this.marketDirectoryList = this.getUpaMarketList(newVal)
        } else {
          this.unionList = []
          this.pauroshobaList = []
          this.marketDirectoryList = this.getDefaultMarketList(this.search)
        }
    },
    'search.city_corporation_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.wardList = this.getWardList(newVal)
          this.marketDirectoryList = this.getCityMarketList(newVal)
        } else {
          this.wardList = []
          this.marketDirectoryList = []
        }
    },
    'search.pauroshoba_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.wardList = this.getWardList(newVal)
          this.marketDirectoryList = this.getPauroMarketList(newVal)
        } else {
          this.wardList = []
          this.marketDirectoryList = []
        }
    },
    'priceAvgSearch.area_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.marketDirectoryListAvg = this.getAreaMarketListAvg(newVal)
      } else {
        this.marketDirectoryListAvg = []
      }
    },
    'priceAvgSearch.division_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.districtListAvg = this.getDistrictListAvg(newVal)
        this.marketDirectoryListAvg = this.getDivMarketListAvg(newVal)
      } else {
        this.districtListAvg = []
        this.marketDirectoryListAvg = []
      }
    },
    'priceAvgSearch.district_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.upazilaListAvg = this.getUpazilaListAvg(newVal)
        this.corporationListAvg = this.getcityCorporationListAvg(newVal)
        this.marketDirectoryListAvg = this.getDisMarketListAvg(newVal)
      } else {
        this.upazilaListAvg = []
        this.corporationListAvg = []
        this.marketDirectoryListAvg = []
      }
    },
    'priceAvgSearch.upazila_id': function (newVal, oldVal) {
        if (newVal) {
          this.unionListAvg = this.getUnionListAvg(newVal)
          this.pauroshobaListAvg = this.getPauroshobaListAvg(newVal)
          this.marketDirectoryListAvg = this.getUpaMarketListAvg(newVal)
        } else {
          this.unionListAvg = []
          this.pauroshobaListAvg = []
          this.marketDirectoryListAvg = this.getDefaultMarketListAvg(this.priceAvgSearch)
        }
    },
    'priceAvgSearch.city_corporation_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.wardListAvg = this.getWardListAvg(newVal)
          this.marketDirectoryListAvg = this.getCityMarketListAvg(newVal)
        } else {
          this.wardListAvg = []
          this.marketDirectoryListAvg = []
        }
    },
    'priceAvgSearch.pauroshoba_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.wardListAvg = this.getWardListAvg(newVal)
          this.marketDirectoryListAvg = this.getPauroMarketListAvg(newVal)
        } else {
          this.wardList = []
          this.marketDirectoryListAvg = []
        }
    }
  },
  computed: {
    areaTypeList: function () {
      const list = this.$store.state.commonObj.areaTypeList
      return list.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    areaTypeListAvg: function () {
      const list = this.$store.state.commonObj.areaTypeList
      return list.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('priceMonitoring.market_inspection_report') + ' ' + this.$t('globalTrans.entry') : this.$t('priceMonitoring.market_inspection_report') + ' ' + this.$t('globalTrans.modify')
    },
    currentLocale () {
      return this.$i18n.locale
    },
    divisionList: function () {
      return this.$store.state.CommonService.commonObj.divisionList.filter(item => item.status === 1)
    },
    divisionListAvg: function () {
      return this.$store.state.CommonService.commonObj.divisionList.filter(item => item.status === 1)
    }
  },
  methods: {
    async cardPrice () {
      this.cardLoading = true
      const result = await RestApi.getData(bazarMonitoringServiceBaseUrl, 'commodity-price-market-monitoring/price-entry/card-price')
      if (result.success) {
        this.cardLoading = false
        this.last_collection_date = result.last_collection_date
        this.second_last_collection_date = result.second_last_collection_date
        this.last_collection_dateWS = result.last_collection_dateWS
        this.second_last_collection_dateWS = result.second_last_collection_dateWS
        const selectedData = result.data
        let count = 1
        this.cardPriceList = selectedData.map((item, index) => {
          let backgroundColor = ''
          if (count === 1) {
            backgroundColor = '#CDDBFF'
          } else if (count === 2) {
            backgroundColor = '#F6D0F6'
          } else if (count === 3) {
            backgroundColor = '#BBFFDD'
          } else if (count === 4) {
            backgroundColor = '#CDDBFF'
          } else if (count === 5) {
            backgroundColor = '#FFF2D9'
          }
          const backData = {
            backgroundColor: backgroundColor
          }
          const selectFieldData = {
            selectField: count++
          }
          if (count > 5) {
            count = 1
          }
          return Object.assign({}, item, selectFieldData, backData)
        })
        this.retailScrollApi()
        return this.cardPriceList
      } else {
        this.cardPriceList = []
      }
    },
    async retailScrollApi () {
      this.loading = false
      const params = Object.assign({}, { second_last_collection_date: this.second_last_collection_date, last_collection_date: this.last_collection_date })
      const result = await RestApi.getData(bazarMonitoringServiceBaseUrl, 'commodity-price-market-monitoring/price-entry/retail-scroll', params)
      if (result.success) {
        // this.loading = false
        this.retailScroll = result.retailScroll
        this.wholeScrollApi()
      } else {
        this.retailScroll = []
      }
    },
    async wholeScrollApi () {
      this.loading = false
      const params = Object.assign({}, { second_last_collection_dateWS: this.second_last_collection_dateWS, last_collection_dateWS: this.last_collection_dateWS })
      const result = await RestApi.getData(bazarMonitoringServiceBaseUrl, 'commodity-price-market-monitoring/price-entry/whole-sale-scroll', params)
      if (result.success) {
        // this.loading = false
        this.wholeSaleScroll = result.wholeSaleScroll
      } else {
        this.wholeSaleScroll = []
      }
    },
    async loadDropdown () {
      this.loading = true
      const result = await RestApi.getData(bazarMonitoringServiceBaseUrl, 'common-dropdowns')
      if (result.success) {
        this.priceCategoryList = result.data.priceCategoryList
        this.commodityGroupList = result.data.commodityGroupList
        this.commodityNameList = result.data.commodityNameList
        this.marketDirectoryListOriginal = result.data.marketDirectoryList.map(item => {
          const textObj = {
            text: this.$i18n.locale === 'bn' ? item.text_bn : item.text_en
          }
          return Object.assign({}, item, textObj)
        })
        this.marketDirectoryListAvgOriginal = result.data.marketDirectoryList.map(item => {
          const textObj = {
            text: this.$i18n.locale === 'bn' ? item.text_bn : item.text_en
          }
          return Object.assign({}, item, textObj)
        })
        this.unitList = result.data.unitList
        this.searchData()
      } else {
        this.priceCategoryList = []
        this.commodityGroupList = []
        this.commodityNameList = []
        this.marketDirectoryList = []
        this.unitList = []
      }
    },
    async searchData () {
       if (this.search.area_type_id) {
        const areaTypeObj = this.$store.state.commonObj.areaTypeList.find(areaType => areaType.value === this.search.area_type_id)
        this.searchHeaderData.area_type = areaTypeObj !== undefined ? areaTypeObj.text_en : ''
        this.searchHeaderData.area_type_bn = areaTypeObj !== undefined ? areaTypeObj.text_bn : ''
      }
      if (this.search.division_id) {
        const divisionObj = this.$store.state.CommonService.commonObj.divisionList.find(division => division.value === this.search.division_id)
        this.searchHeaderData.division_name = divisionObj !== undefined ? divisionObj.text_en : ''
        this.searchHeaderData.division_name_bn = divisionObj !== undefined ? divisionObj.text_bn : ''
      }
      if (this.search.district_id) {
        const districtObj = this.$store.state.CommonService.commonObj.districtList.find(district => district.value === this.search.district_id)
        this.searchHeaderData.district_name = districtObj !== undefined ? districtObj.text_en : ''
        this.searchHeaderData.district_name_bn = districtObj !== undefined ? districtObj.text_bn : ''
      }
      if (this.search.upazila_id) {
        const upazilaObj = this.$store.state.CommonService.commonObj.upazilaList.find(upazila => upazila.value === this.search.upazila_id)
        this.searchHeaderData.upazila_name = upazilaObj !== undefined ? upazilaObj.text_en : ''
        this.searchHeaderData.upazila_name_bn = upazilaObj !== undefined ? upazilaObj.text_bn : ''
      }
      if (this.search.city_corporation_id) {
        const cityCorporationObj = this.$store.state.CommonService.commonObj.cityCorporationList.find(cityCor => cityCor.value === this.search.city_corporation_id)
        this.searchHeaderData.city_corporation = cityCorporationObj !== undefined ? cityCorporationObj.text_en : ''
        this.searchHeaderData.city_corporation_bn = cityCorporationObj !== undefined ? cityCorporationObj.text_bn : ''
      }
      if (this.search.union_id) {
        const unionObj = this.$store.state.CommonService.commonObj.unionList.find(union => union.value === this.search.union_id)
        this.searchHeaderData.union_name = unionObj !== undefined ? unionObj.text_en : ''
        this.searchHeaderData.union_name_bn = unionObj !== undefined ? unionObj.text_bn : ''
      }
      if (this.search.pauroshoba_id) {
        const pauroshobaObj = this.$store.state.CommonService.commonObj.municipalityList.find(pauroshoba => pauroshoba.value === this.search.pauroshoba_id)
        this.searchHeaderData.pauroshoba_name = pauroshobaObj !== undefined ? pauroshobaObj.text_en : ''
        this.searchHeaderData.pauroshoba_name_bn = pauroshobaObj !== undefined ? pauroshobaObj.text_bn : ''
      }
      if (this.search.market_directory_id) {
        const marketDirectoryObj = this.marketDirectoryList.find(market => market.value === this.search.market_directory_id)
        this.searchHeaderData.market_name_en = marketDirectoryObj !== undefined ? marketDirectoryObj.text_en : ''
        this.searchHeaderData.market_name_bn = marketDirectoryObj !== undefined ? marketDirectoryObj.text_bn : ''
      }
      this.showData = true
      this.bazarDorLoading = true
      const params = Object.assign({}, this.search)
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(bazarMonitoringServiceBaseUrl, '/report/market-wise-price', params)
      if (result.success) {
        this.datas = this.getRelationData(result.data)
        this.priceCategoryArr = this.getRelationPriceCategory(result.price_category_arr)
        this.priceCategoryArrNew = this.priceCategoryArr.filter(item => item.value === 5)
        this.bazarDorLoading = false
        // this.loadData()
      } else {
        this.bazarDorLoading = false
        this.datas = []
        this.priceCategoryArr = []
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getDistrictList (id) {
      return this.$store.state.CommonService.commonObj.districtList.filter(item => item.status === 1 && item.division_id === id)
    },
    getUpazilaList (id) {
      return this.$store.state.CommonService.commonObj.upazilaList.filter(item => item.status === 1 && item.district_id === id)
    },
    getcityCorporationList (id) {
      return this.$store.state.CommonService.commonObj.cityCorporationList.filter(item => item.status === 1 && item.district_id === id)
    },
    getUnionList (upazilaId) {
      return this.$store.state.CommonService.commonObj.unionList.filter(item => item.status === 1 && item.upazila_id === upazilaId)
    },
    getWardList (id) {
      return this.$store.state.CommonService.commonObj.wardList.filter(item => item.status === 1 && item.city_corporation_id === id)
    },
    getPauroshobaList (id) {
      return this.$store.state.CommonService.commonObj.municipalityList.filter(item => item.status === 1 && item.upazila_id === id)
    },
    getAreaMarketList (id) {
      return this.marketDirectoryListOriginal.filter(item => item.status === 1 && item.area_type_id === id)
    },
    getDivMarketList (id) {
      return this.marketDirectoryListOriginal.filter(item => item.status === 1 && item.division_id === id)
    },
    getDisMarketList (id) {
      return this.marketDirectoryListOriginal.filter(item => item.status === 1 && item.district_id === id)
    },
    getUpaMarketList (id) {
      return this.marketDirectoryListOriginal.filter(item => item.status === 1 && item.upazila_id === id)
    },
    getUniMarketList (id) {
      return this.marketDirectoryListOriginal.filter(item => item.status === 1 && item.union_id === id)
    },
    getPauroMarketList (id) {
      return this.marketDirectoryListOriginal.filter(item => item.status === 1 && item.pauroshoba_id === id)
    },
    getCityMarketList (id) {
      return this.marketDirectoryListOriginal.filter(item => item.status === 1 && item.city_corporation_id === id)
    },
    getDefaultMarketList (data) {
      return this.marketDirectoryListOriginal.filter(item => item.status === 1 && item.area_type_id === data.area_type_id && item.division_id === data.division_id && item.district_id === data.district_id)
    },
    getAreaMarketListAvg (id) {
      return this.marketDirectoryListAvgOriginal.filter(item => item.status === 1 && item.area_type_id === id)
    },
    getDivMarketListAvg (id) {
      return this.marketDirectoryListAvgOriginal.filter(item => item.status === 1 && item.division_id === id)
    },
    getDisMarketListAvg (id) {
      return this.marketDirectoryListAvgOriginal.filter(item => item.status === 1 && item.district_id === id)
    },
    getUpaMarketListAvg (id) {
      return this.marketDirectoryListAvgOriginal.filter(item => item.status === 1 && item.upazila_id === id)
    },
    getUniMarketListAvg (id) {
      return this.marketDirectoryListAvgOriginal.filter(item => item.status === 1 && item.union_id === id)
    },
    getPauroMarketListAvg (id) {
      return this.marketDirectoryListAvgOriginal.filter(item => item.status === 1 && item.pauroshoba_id === id)
    },
    getCityMarketListAvg (id) {
      return this.marketDirectoryListAvgOriginal.filter(item => item.status === 1 && item.city_corporation_id === id)
    },
    getDefaultMarketListAvg (data) {
      return this.marketDirectoryListAvgOriginal.filter(item => item.status === 1 && item.area_type_id === data.area_type_id && item.division_id === data.division_id && item.district_id === data.district_id)
    },
    getDistrictListAvg (id) {
      return this.$store.state.CommonService.commonObj.districtList.filter(item => item.status === 1 && item.division_id === id)
    },
    getUpazilaListAvg (id) {
      return this.$store.state.CommonService.commonObj.upazilaList.filter(item => item.status === 1 && item.district_id === id)
    },
    getcityCorporationListAvg (id) {
      return this.$store.state.CommonService.commonObj.cityCorporationList.filter(item => item.status === 1 && item.district_id === id)
    },
    getUnionListAvg (upazilaId) {
      return this.$store.state.CommonService.commonObj.unionList.filter(item => item.status === 1 && item.upazila_id === upazilaId)
    },
    getWardListAvg (id) {
      return this.$store.state.CommonService.commonObj.wardList.filter(item => item.status === 1 && item.city_corporation_id === id)
    },
    getPauroshobaListAvg (id) {
      return this.$store.state.CommonService.commonObj.municipalityList.filter(item => item.status === 1 && item.upazila_id === id)
    },
    getRelationData (data) {
      const listData = data.map(item => {
        const commodityObj = this.commodityGroupList.find(commodityGroup => commodityGroup.value === parseInt(item.commodity_group_id))
        const commodityData = {
          commodity_group: commodityObj !== undefined ? commodityObj.text_en : '',
          commodity_group_bn: commodityObj !== undefined ? commodityObj.text_bn : ''
        }
        item.commodity_name_info = item.commodity_name_info.map(item2 => {
          const commodityNameObj = this.commodityNameList.find(commodityName => commodityName.value === item2.commodity_name_id)
          const commodityNameData = {
            commodity_name: commodityNameObj !== undefined ? commodityNameObj.text_en : '',
            commodity_name_bn: commodityNameObj !== undefined ? commodityNameObj.text_bn : ''
          }
          const measurementUnitObj = this.unitList.find(unit => unit.value === item2.measurement_unit_id)
          const unitData = {
            unit_name: measurementUnitObj !== undefined ? measurementUnitObj.text_en : '',
            unit_name_bn: measurementUnitObj !== undefined ? measurementUnitObj.text_bn : ''
          }
          return Object.assign({}, item2, commodityNameData, unitData)
        })
        return Object.assign({}, item, commodityData)
      })
      return listData
    },
    getRelationPriceCategory (data) {
      const dataRelation = data.map(item => {
        const priceCategoryObj = this.priceCategoryList.find(price => price.value === item)
        const data = {
          value: priceCategoryObj !== undefined ? priceCategoryObj.value : '',
          price_category: priceCategoryObj !== undefined ? priceCategoryObj.text_en : '',
          price_category_bn: priceCategoryObj !== undefined ? priceCategoryObj.text_bn : ''
        }
        return Object.assign({}, item, data)
      })
      return dataRelation
    },
    getPriceCategoryInfo (priceCategoryId, priceCategoryArr) {
      const priceCategory = priceCategoryArr.find(item => item.price_category_id === parseInt(priceCategoryId))
      return priceCategory
    },
    async loadData () {
      this.reportLoading = true
      if (this.priceAvgSearch.price_category_id) {
        const priceCategoryObj = this.priceCategoryList.find(data => data.value === this.priceAvgSearch.price_category_id)
        this.priceAvgSearchHeaderData.price_category_name = priceCategoryObj !== undefined ? priceCategoryObj.text_en : ''
        this.priceAvgSearchHeaderData.price_category_name_bn = priceCategoryObj !== undefined ? priceCategoryObj.text_bn : ''
      }
      if (this.priceAvgSearch.frequency_type.length) {
        const arrEn = []
        const arrBn = []
        this.priceAvgSearch.frequency_type.forEach(item => {
          const frequencyType = this.frequencyTypeList2.find(data => data.value === parseInt(item))
          if (frequencyType !== undefined) {
            arrEn.push(frequencyType.text_en)
            arrBn.push(frequencyType.text_bn)
          }
        })
        this.priceAvgSearchHeaderData.frequency_name = arrEn.toString()
        this.priceAvgSearchHeaderData.frequency_name_bn = arrBn.toString()
      }
      if (this.priceAvgSearch.division_id) {
        const divisionObj = this.$store.state.CommonService.commonObj.divisionList.find(division => division.value === this.priceAvgSearch.division_id)
        this.priceAvgSearchHeaderData.division_name = divisionObj !== undefined ? divisionObj.text_en : ''
        this.priceAvgSearchHeaderData.division_name_bn = divisionObj !== undefined ? divisionObj.text_bn : ''
      }
      if (this.priceAvgSearch.district_id) {
        const districtObj = this.$store.state.CommonService.commonObj.districtList.find(district => district.value === this.priceAvgSearch.district_id)
        this.priceAvgSearchHeaderData.district_name = districtObj !== undefined ? districtObj.text_en : ''
        this.priceAvgSearchHeaderData.district_name_bn = districtObj !== undefined ? districtObj.text_bn : ''
      }
      if (this.priceAvgSearch.city_corporation_id) {
        const cityCorporationObj = this.$store.state.CommonService.commonObj.cityCorporationList.find(obj => obj.value === this.priceAvgSearch.city_corporation_id)
        this.priceAvgSearchHeaderData.city_corporation_name = cityCorporationObj !== undefined ? cityCorporationObj.text_en : ''
        this.priceAvgSearchHeaderData.city_corporation_name_bn = cityCorporationObj !== undefined ? cityCorporationObj.text_bn : ''
      }
      if (this.priceAvgSearch.upazila_id) {
        const upazilaObj = this.$store.state.CommonService.commonObj.upazilaList.find(upazila => upazila.value === this.priceAvgSearch.upazila_id)
        this.priceAvgSearchHeaderData.upazila_name = upazilaObj !== undefined ? upazilaObj.text_en : ''
        this.priceAvgSearchHeaderData.upazila_name_bn = upazilaObj !== undefined ? upazilaObj.text_bn : ''
      }
      if (this.priceAvgSearch.pauroshoba_id) {
        const pauroshobaObj = this.$store.state.CommonService.commonObj.municipalityList.find(obj => obj.value === this.priceAvgSearch.pauroshoba_id)
        this.priceAvgSearchHeaderData.pauroshoba_name = pauroshobaObj !== undefined ? pauroshobaObj.text_en : ''
        this.priceAvgSearchHeaderData.pauroshoba_name_bn = pauroshobaObj !== undefined ? pauroshobaObj.text_bn : ''
      }
      if (this.priceAvgSearch.union_id) {
        const unionObj = this.$store.state.CommonService.commonObj.unionList.find(union => union.value === this.priceAvgSearch.union_id)
        this.priceAvgSearchHeaderData.union_name = unionObj !== undefined ? unionObj.text_en : ''
        this.priceAvgSearchHeaderData.union_name_bn = unionObj !== undefined ? unionObj.text_bn : ''
      }
      if (this.priceAvgSearch.market_directory_id) {
        const marketDirectoryObj = this.marketDirectoryList.find(market => market.value === this.priceAvgSearch.market_directory_id)
        this.priceAvgSearchHeaderData.market_name_en = marketDirectoryObj !== undefined ? marketDirectoryObj.text_en : ''
        this.priceAvgSearchHeaderData.market_name_bn = marketDirectoryObj !== undefined ? marketDirectoryObj.text_bn : ''
      }
      if (this.priceAvgSearch.commodity_group_id.length) {
        const arrEn = []
        const arrBn = []
        this.priceAvgSearch.commodity_group_id.forEach(item => {
          const commodityGroup = this.commodityGroupList.find(obj => obj.value === parseInt(item))
          if (commodityGroup !== undefined) {
            arrEn.push(commodityGroup.text_en)
            arrBn.push(commodityGroup.text_bn)
          }
        })
        this.priceAvgSearchHeaderData.commodity_group_name_en = arrEn.toString()
        this.priceAvgSearchHeaderData.commodity_group_name_bn = arrBn.toString()
      }
      if (this.priceAvgSearch.commodity_name_id.length) {
        const arrEn = []
        const arrBn = []
        this.priceAvgSearch.commodity_name_id.forEach(item => {
          const commodityName = this.commodityNameList.find(obj => obj.value === parseInt(item))
          if (commodityName !== undefined) {
            arrEn.push(commodityName.text_en)
            arrBn.push(commodityName.text_bn)
          }
        })
        this.priceAvgSearchHeaderData.commodity_name_en = arrEn.toString()
        this.priceAvgSearchHeaderData.commodity_name_bn = arrBn.toString()
      }
      this.showData = true
      const params = Object.assign({}, this.priceAvgSearch)
      // this.loading = true
      const result = await RestApi.getData(bazarMonitoringServiceBaseUrl, '/report/price-average-report', params)
      if (result.success) {
        this.reportLoading = false
        const frequencyArr = []
        for (const frequency in result.frequency_date_info) {
          if (Object.prototype.hasOwnProperty.call(result.frequency_date_info, frequency)) {
            const frequencyDate = result.frequency_date_info[frequency]
            let frequencyItem = {}
            if (parseInt(frequency) === 1) {
              frequencyItem = {
                frequency_type: parseInt(frequency),
                frequency_text_en: this.$t('report.todays_price_tk', 'en'),
                frequency_text_bn: this.$t('report.todays_price_tk', 'bn'),
                frequency_date: frequencyDate
              }
              frequencyArr.push(frequencyItem)
            } else if (parseInt(frequency) === 2) {
              frequencyItem = {
                frequency_type: parseInt(frequency),
                frequency_text_en: this.$t('report.last_weeks_price_tk', 'en'),
                frequency_text_bn: this.$t('report.last_weeks_price_tk', 'bn'),
                frequency_date: frequencyDate
              }
              frequencyArr.push(frequencyItem)
            } else if (parseInt(frequency) === 3) {
              frequencyItem = {
                frequency_type: parseInt(frequency),
                frequency_text_en: this.$t('report.last_months_price_tk', 'en'),
                frequency_text_bn: this.$t('report.last_months_price_tk', 'bn'),
                frequency_date: frequencyDate
              }
              frequencyArr.push(frequencyItem)
            } else if (parseInt(frequency) === 4) {
              frequencyItem = {
                frequency_type: parseInt(frequency),
                frequency_text_en: this.$t('report.last_quarter_price_tk', 'en'),
                frequency_text_bn: this.$t('report.last_quarter_price_tk', 'bn'),
                frequency_date: frequencyDate
              }
              frequencyArr.push(frequencyItem)
            } else if (parseInt(frequency) === 5) {
              frequencyItem = {
                frequency_type: parseInt(frequency),
                frequency_text_en: this.$t('report.last_half_years_price_tk', 'en'),
                frequency_text_bn: this.$t('report.last_half_years_price_tk', 'bn'),
                frequency_date: frequencyDate
              }
              frequencyArr.push(frequencyItem)
            } else if (parseInt(frequency) === 6) {
              frequencyItem = {
                frequency_type: parseInt(frequency),
                frequency_text_en: this.$t('report.last_years_price_tk', 'en'),
                frequency_text_bn: this.$t('report.last_years_price_tk', 'bn'),
                frequency_date: frequencyDate
              }
              frequencyArr.push(frequencyItem)
            }
          }
        }
        this.frequencyDateArr = frequencyArr
        this.priceAvgSearch.frequency_type_check = this.priceAvgSearch.frequency_type
        // this.$store.dispatch('setList', this.getCustomizedList(result.data))
        this.listData = this.getCustomizedList(result.data)
      } else {
        // this.$store.dispatch('setList', [])
        this.listData = []
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      // this.loading = false
    },
    getCustomizedList (data) {
      const list = data.map(item => {
        item.commodity_name_info = item.commodity_name_info.map(commodityNameInfo => {
          commodityNameInfo.price_category_info = commodityNameInfo.price_category_info.map(priceCategoryInfo => {
            const frequencyTypeInfo = []
            this.frequencyDateArr.forEach((frequencyItem, frequencyIndex) => {
              let frequencyInfo
              frequencyInfo = priceCategoryInfo.frequency_type_info.find(obj => parseInt(obj.frequency_type) === parseInt(frequencyItem.frequency_type))
              if (frequencyInfo === undefined) {
                frequencyInfo = {
                  frequency_type: parseInt(frequencyItem.frequency_type),
                  lowest_price_amount: 0,
                  highest_price_amount: 0,
                  fluctuation_rate: 0,
                  fluctuation_rate_percentage: 0
                }
              }
              frequencyTypeInfo.push(frequencyInfo)
            })
            priceCategoryInfo.frequency_type_info = frequencyTypeInfo
            const priceCategory = this.priceCategoryList.find(obj => obj.value === parseInt(priceCategoryInfo.price_category_id))
            const customItem = {
              price_category_name_en: priceCategory?.text_en,
              price_category_name_bn: priceCategory?.text_bn
            }
            return Object.assign({}, priceCategoryInfo, customItem)
          })
          const commodityName = this.commodityNameList.find(obj => obj.value === parseInt(commodityNameInfo.commodity_name_id))
          const customItem = {
            commodity_name_en: commodityName?.text_en,
            commodity_name_bn: commodityName?.text_bn
          }
          return Object.assign({}, commodityNameInfo, customItem)
        })
        const commodityGroup = this.commodityGroupList.find(obj => obj.value === parseInt(item.commodity_group_id))
        const customItem = {
          commodity_group_name_en: commodityGroup?.text_en,
          commodity_group_name_bn: commodityGroup?.text_bn
        }
        return Object.assign({}, item, customItem)
      })
      return list
    },
    backgroundImageCheck (item) {
      let backgroundImage = ''
      if (parseInt(item.selectField) === 1) {
        backgroundImage = this.backgroundImage1
      }
      if (parseInt(item.selectField) === 2) {
        backgroundImage = this.backgroundImage2
      }
      if (parseInt(item.selectField) === 3) {
        backgroundImage = this.backgroundImage3
      }
      if (parseInt(item.selectField) === 4) {
        backgroundImage = this.backgroundImage4
      }
      if (parseInt(item.selectField) === 5) {
        backgroundImage = this.backgroundImage5
      }
      return backgroundImage
    }
  }
}
</script>
<style>
  .mc-report-card {
    position: relative;
    margin-bottom: 10px;
    border-radius: var(--border-radius-md);
  }
  .icon-wrapper {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translate(0, -50%);
    color: rgba(255, 255, 255, .3);
    font-size: 3.6rem;
  }
  @media (min-width: 576px) {
    .custom-col {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }

  .tableFixHead > .tableFixHead  th {
  border: 1px solid black !important;
}
.tableFixHead >.table-bordered thead th, .table-bordered thead td {
  background: white  !important;
  border: 1px solid black !important;
}
.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
}

.first-colulm {
  width: 85px;
  min-width: 85px;
  max-width: 85px;
  left: 0px;
  background-color: white !important;
  z-index: 3;
}

.second-column {
  width: 131px;
  min-width: 205px;
  max-width: 205px;
  left: 42px !important;
  background-color: white !important;
  z-index: 3;
}

.third-column {
  width: 131px;
  min-width: 205px;
  max-width: 205px;
  left: 240px !important;
  background-color: white !important;
  z-index: 3;
}
.fourth-column {
  width: 131px;
  min-width: 205px;
  max-width: 205px;
  left: 430px !important;
  background-color: white !important;
  z-index: 3;
}
.underzindex {
  z-index: 3 !important;
}
.top-75 {
  top: 75px !important;
}
.top-58 {
  top: 58px !important;
}
/* .b-table-sticky-header > .table.b-table > thead > tr > th {
  position: sticky !important;
  top: 0;
} */
.topzindex {
  z-index: 5 !important;
}
.rightzindex {
  z-index: 4 !important;
}

/* .tableFixHead >.b-table-sticky-header > .table.b-table > thead > tr > th {
  position: sticky !important;
  top: 0;
  font-size: 15px  !important;
} */
.b-table-sticky-header {
  overflow-y: auto;
  max-height: 400px !important;
}

.table-responsive {
    max-height: 400px;
    overflow-y: scroll;
  }
  .top-38 {
    top: 38px !important;
  }
  .top-80 {
    top: 80px !important;
  }
  .top-95 {
    top: 95px !important;
  }
  z-index-5 {
    z-index: 5 !important;
  }
  z-index-4 {
    z-index: 4 !important;
  }
  z-index-3 {
    z-index: 3 !important;
  }
  .first-column-1 {
    width: 85px;
    min-width: 85px;
    max-width: 85px;
    left: 0px;
    background-color: white !important;
    z-index: 3;
  }

  .second-column-1 {
    width: 165px;
    min-width: 165px;
    max-width: 165px;
    left: 65px !important;
    background-color: white !important;
    z-index: 3;
  }

  .third-column-1 {
    width: 205px;
    min-width: 205px;
    max-width: 205px;
    left: 230px !important;
    background-color: white !important;
    z-index: 3;
  }
  .fourth-column-1 {
    width: 131px;
    min-width: 131px;
    max-width: 131px;
    left: 430px !important;
    background-color: white !important;
    z-index: 3;
  }
</style>
