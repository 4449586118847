<template>
    <div>
        <!-- v-year-calendar-component -->
        <Calendar
            :enable-range-selection="true"
            :data-source="dataSource"
            :enable-context-menu="true"
            :context-menu-items="contextMenuItems"
            @select-range="selectRange"
        >
        </Calendar>
        <!-- modal for show fair info -->
        <b-modal :title="currentLocale == 'bn' ? 'মেলার তথ্য দেখুন' : 'View Fair Info'" v-model="showModal" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <b-card v-for="(event, index) in currentEvents" :key="index" style="background-color:#f1f1f4;">
                <b-card-body>
                    <b>{{ $t('tradeFairProposalManagement.name_and_title') }}</b>
                     : {{ currentLocale == 'bn' ?  event.name_bn : event.name }} <br>

                    <b>{{ $t('tradeFairProposalManagement.city_country') }}</b> : {{ currentLocale == 'bn' ?  event.location_bn : event.location }}, {{ getCountry(parseInt(event.country_id)) }} <br>

                    <b>{{ $t('externalTradeFair.duration') }}</b>: {{ dateData(event.startDate) }} <b> {{ (currentLocale === 'bn' ? 'হতে' : 'to') }} </b> {{ dateData(event.endDate) }}
                </b-card-body>
            </b-card>
        </b-modal>
    </div>
</template>

<script>
import Calendar from 'v-year-calendar'
import moment from 'moment'
export default {
  components: { Calendar },
  props: ['scheduleData'],
  data: function () {
    return {
      showModal: false,
      currentId: null,
      currentStartDate: null,
      currentEndDate: null,
      currentName: null,
      currentLocation: null,
      dataSource: [],
      currentEvents: [],
      shouldRender: false,
      contextMenuItems: []
    }
  },
  created () {
    this.dataSource = this.scheduleData
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    getCountry (countryId) {
      const countryObj = this.$store.state.CommonService.commonObj.countryList.find(el => el.value === parseInt(countryId))
      if (countryObj !== undefined) {
        return this.currentLocale === 'bn' ? countryObj.text_bn : countryObj.text_en
      }
    },
    dateData (fdate) {
      require('moment/min/locales.min')
      moment.locale(this.$i18n.locale)
      const date = moment(fdate).format('Do MMMM, YYYY')
      return date
    },
    selectRange: function (e) {
      this.currentId = null
      this.currentName = null
      this.currentLocation = null
      this.currentStartDate = e.startDate.toISOString().substring(0, 10)
      this.currentEndDate = e.endDate.toISOString().substring(0, 10)
      this.showModal = true
      this.currentEvents = e.events
    }
  }
}
</script>
