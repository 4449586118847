<template>
  <div>
    <div class="price_update">
      <h2 class="marquee_title">
        <b-container fluid>{{ $t('ministry_dashboard.daily_national_avg_price_retail') }}</b-container>
      </h2>
      <b-container fluid>
        <marquee
          behavior="scroll"
          direction="slide"
          onmouseover="this.stop ();"
          onmouseout="this.start ()"
        >
          <div class="d-inline" v-for="(item, index) in retailScroll" :key="index">
            <span>{{ $i18n.locale === 'bn' ? item.commodity_name_bn : item.commodity_name_en }} ({{ ($i18n.locale==='bn') ? item.unit_name_bn : item.unit_name_en }} )</span>
            <span class="mx-3 font-weight-bold" :class="colorCheck(item)">
              {{ $n(item.lowest_price_amount) }} -
              {{ $n(item.highest_price_amount) }}
              <slot v-if="item.percentage === '0.00'"> </slot>
              <slot v-else>
                <slot v-if="item.percentage">
                  <slot v-if="item.percentage < 0">
                    <span style="color: var(--brand-secondary-color)"
                      >▼ {{ $n(item.percentage) }} %</span
                    >
                  </slot>
                  <slot v-else>
                    <span style="color: #ff0000"
                      >▲ {{ $n(item.percentage) }} %</span
                    >
                  </slot>
                </slot>
              </slot>
            </span>
          </div>
        </marquee>
      </b-container>
    </div>
    <div class="price_update mt-2">
      <h2 class="marquee_title">
        <b-container fluid>{{ $t('ministry_dashboard.daily_national_avg_price_wholesale') }}</b-container>
      </h2>
      <b-container fluid>
        <marquee
          behavior="scroll"
          direction="slide"
          onmouseover="this.stop ();"
          onmouseout="this.start ()"
        >
          <div class="d-inline" v-for="(item, index) in wholeScroll" :key="index">
            <span>{{ $i18n.locale === 'bn' ? item.commodity_name_bn : item.commodity_name_en }} ({{ ($i18n.locale==='bn') ? item.unit_name_bn : item.unit_name_en }} )</span>
            <span class="mx-3 font-weight-bold" :class="colorCheck(item)">
              {{ $n(item.lowest_price_amount) }} -
              {{ $n(item.highest_price_amount) }}
              <slot v-if="item.percentage === '0.00'"> </slot>
              <slot v-else>
                <slot v-if="item.percentage">
                  <slot v-if="item.percentage < 0">
                    <span style="color: var(--brand-secondary-color)"
                      >▼ {{ $n(item.percentage) }} %</span
                    >
                  </slot>
                  <slot v-else>
                    <span style="color: #ff0000"
                      >▲ {{ $n(item.percentage) }} %</span
                    >
                  </slot>
                </slot>
              </slot>
            </span>
          </div>
        </marquee>
      </b-container>
    </div>
    <div class="mt-3 text-center">
      <span class="mx-3 font-weight-bold text-danger" v-if="retailPriceDate">
        *** {{ $t('ministry_dashboard.retail_price_date') }} : {{ retailPriceDate|dateFormat }} ***
      </span>
      <span class="mx-3 font-weight-bold text-danger" v-if="wholeSalePriceDate">
        *** {{ $t('ministry_dashboard.ws_retail_price_date') }} : {{ wholeSalePriceDate|dateFormat }} ***
      </span>
    </div>
  </div>
</template>

<script>

export default {
  props: ['retailScroll', 'wholeScroll', 'retailPriceDate', 'wholeSalePriceDate'],
  created () {
  },
  data () {
    return {
      datas: [],
      UpdateNews: [
        {
          title: 'কাতল- মাঝারি (কিলোগ্রাম ) : ৩৪১.৪৭১ - ৩৮৫.২৯৪',
          value: '০.৩৫ %'
        },
        {
          title: 'চাল-সরু (কেজি ) : ৬৮ - ৭২',
          value: '২.৩ %'
        }
      ],
      marqueeAnimation: 'marquee 10s linear infinite',
      showMarquee: true
    }
  },
  methods: {
    colorCheck (item) {
      if (typeof item.percentage === 'undefined') {
        return ''
      }
      if (item.percentage === '0') {
        return ''
      }
      if (item.percentage < 0) {
        return 'text-success'
      }
      if (item.percentage > 0) {
        return 'text-danger'
      }
      return ''
    },
    stopScroll () {
      this.showMarquee = false
    },
    startScroll () {
      this.showMarquee = true
    }
  }
}
</script>

<style lang="scss" scoped>
.price_update {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #d7f7ff;
  .marquee_title {
    background-color: crimson;
    font-size: 15px;
    color: var(--white);
    line-height: 25px;
    padding: 5px 4px;
    display: inline-block;
    margin: 0;
    min-width: 350px;
  }
}
.font-en{

  *{
    .marquee_title{
        font-size: 12px;
    }
  }
}
</style>
