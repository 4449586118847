<template>
    <div>
      <b-overlay :show="loading">
        <body-card>
              <template v-slot:body>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-table-simple small hover responsive bordered>
                      <b-thead>
                        <b-tr>
                          <b-th width="12%">{{ $t('globalTrans.sl_no') }}</b-th>
                          <b-th>{{ $t('teaGardenConfig.company_name') }}</b-th>
                          <b-th>{{ $t('teaGardenConfig.tea_garden_name') }}</b-th>
                          <b-th>{{ $t('globalTrans.status') }}</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <template v-if="listData.length">
                          <b-tr v-for="(item, index) in listData" :key="index">
                            <b-td>{{ $n(index + 1) }}</b-td>
                            <b-td>{{ getCompanyName(item.tea_company_id) }}</b-td>
                            <b-td>{{ currentLocale === 'en' ? item.tea_garden_name_en : item.tea_garden_name_bn }}</b-td>
                            <b-td>
                              <span class="badge badge-success" v-if="item.status == 1">{{$t('globalTrans.active')}}</span>
                              <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                            </b-td>
                          </b-tr>
                        </template>
                        <template v-else>
                          <b-tr>
                            <b-td align="center" colspan="4">{{ $t('globalTrans.noDataFound') }}</b-td>
                          </b-tr>
                        </template>
                      </b-tbody>
                    </b-table-simple>
                  </b-col>
                </b-row>
              </template>
            </body-card>
      </b-overlay>
    </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
export default {
    name: 'GardenList',
    props: ['masterTeaCompanyList'],
    data () {
      return {
        teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        loading: false,
        listData: []
      }
    },
    async created () {
      await this.loadData()
    },
    computed: {
      currentLocale () {
        return this.$i18n.locale
      }
    },
    methods: {
      async loadData () {
        const params = { show_all: 1 }
        this.loading = true
        const result = await RestApi.getData(teaGardenServiceBaseUrl, '/configuration/tea-garden-gen-info/list', params)
        if (result.data.success) {
          this.listData = result.data.data
        } else {
          this.listData = []
        }
        this.loading = false
      },
      getCompanyName (id) {
        const obj = this.masterTeaCompanyList.find(item => item.value === parseInt(id))
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      }
    }
}
</script>
