<template>
  <div>
  <b-row>
    <b-col lg="12" sm="12">
        <b-row>
          <b-col lg="12" sm="12">
            <table style="width:100%">
              <thead>
                <tr style="background: #ddd;" >
                  <th class="text-center">{{ $t('globalTrans.sl_no') }}</th>
                  <th class="text-center">{{ $t('globalTrans.fiscal_year') }}</th>
                  <th class="text-center">{{ $t('tradeFairInfoManagement.circular_memo_no') }}</th>
                  <th class="text-center">{{ $t('tradeFairInfoManagement.subject') }}</th>
                </tr>
              </thead>
              <tbody v-if="upCommingList.length > 0">
                  <tr v-for="(item, index) in upCommingList" :key="index">
                      <td>{{ $n(index+1) }}</td>
                      <td>{{ getFiscalYear(item.fiscal_year_id) }}</td>
                      <td>{{ item.circular_memo_no }}</td>
                      <td>{{ currentLocale === 'bn' ? item.subject_bn : item.subject_en }}</td>
                  </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="4" class="text-center"> {{ $t('fair_report.not_found') }} </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="text-right mt-1">
          <b-col>
            <b-button v-if="!attended" variant="danger" class="btn-sm" @click="$bvModal.hide('modal-upcomming')">{{ $t('globalTrans.cancel') }}</b-button>
            <b-button v-if="attended" variant="danger" class="btn-sm" @click="$bvModal.hide('modal-attended')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
    </b-col>
  </b-row>
  </div>
</template>
<script>
import { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
export default {
  name: 'Details',
  props: ['upCommingList', 'attended'],
  components: {},
  data () {
    return {
      baseUrl: internationalTradeFairServiceBaseUrl
    }
  },
  created () {
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList
    },
    currentFiscalYearId () {
      return this.$store.state.currentFiscalYearId
    }
  },
  watch: {
  },
  methods: {
    getFiscalYear (id) {
      const data = this.fiscalYearList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
          return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
          return ''
      }
    }
  }
}
</script>
<style scoped>
  table, th, td {
    border: 1px solid black;
    padding:5px;
  }
</style>
