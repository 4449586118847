<template>
    <div>
      <b-overlay :show="loading">
        <body-card>
              <template v-slot:body>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-table-simple small hover responsive bordered>
                      <b-thead>
                        <b-tr>
                          <b-th width="12%">{{ $t('globalTrans.sl_no') }}</b-th>
                          <b-th>{{ $t('exportTrophyGazette.title') }}</b-th>
                          <b-th>{{ $t('globalTrans.date') }}</b-th>
                          <b-th>{{ $t('eBizProgram.memo_no') }}</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <template v-if="listData.length">
                          <b-tr v-for="(item, index) in listData" :key="index">
                            <b-td>{{ $n(index + 1) }}</b-td>
                            <b-td>{{ currentLocale === 'bn' ? item.title_bn : item.title_en }}</b-td>
                            <b-td>{{ item.date | dateFormat }}</b-td>
                            <b-td>{{ item.memo_no }}</b-td>
                          </b-tr>
                        </template>
                        <template v-else>
                          <b-tr>
                            <b-td align="center" colspan="4">{{ $t('globalTrans.noDataFound') }}</b-td>
                          </b-tr>
                        </template>
                      </b-tbody>
                    </b-table-simple>
                  </b-col>
                </b-row>
              </template>
            </body-card>
      </b-overlay>
    </div>
</template>
<script>
import RestApi, { exportTrophyCIPServiceBaseUrl } from '@/config/api_config'
export default {
    props: ['search'],
    data () {
      return {
        exportTrophyCIPServiceBaseUrl: exportTrophyCIPServiceBaseUrl,
        loading: false,
        listData: []
      }
    },
    async created () {
      await this.loadData()
    },
    computed: {
      currentLocale () {
        return this.$i18n.locale
      }
    },
    methods: {
      async loadData () {
        this.loading = true
        const params = { year: this.search.year }
        const result = await RestApi.getData(exportTrophyCIPServiceBaseUrl, '/gazette/cip-approve-list', params)
        if (result.success) {
          this.listData = result.data
        } else {
          this.listData = []
        }
        this.loading = false
      }
    }
}
</script>
