<template>
    <div>
      <b-overlay :show="loading">
        <body-card>
              <template v-slot:body>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-table-simple small hover responsive bordered>
                      <b-thead>
                        <b-tr>
                          <b-th width="12%">{{ $t('globalTrans.sl_no') }}</b-th>
                          <b-th>{{ $t('teaGardenConfig.registration_no') }}</b-th>
                          <b-th>{{ $t('teaGardenConfig.factory_name') }}</b-th>
                          <b-th>{{ $t('teaGardenConfig.factory_type') }}</b-th>
                          <b-th>{{ $t('teaGardenConfig.select_made_tea_type') }}</b-th>
                          <b-th>{{ $t('teaGardenConfig.division') }}</b-th>
                          <b-th>{{ $t('teaGardenConfig.district') }}</b-th>
                          <b-th>{{ $t('globalTrans.status') }}</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <template v-if="listData.length">
                          <b-tr v-for="(item, index) in listData" :key="index">
                            <b-td>{{ $n(index + 1) }}</b-td>
                            <b-td>{{ item.registration_no | numberConvert }}</b-td>
                            <b-td>{{ currentLocale === 'en' ? item.factory_name_en : item.factory_name_bn }}</b-td>
                            <b-td>{{ currentLocale === 'en' ? item.factory_type_en : item.factory_type_bn }}</b-td>
                            <b-td>{{ getMadeTypeNames(JSON.parse(item.made_tea_type_id)) }}</b-td>
                            <b-td>{{ currentLocale === 'en' ? item.division_name_en : item.division_name_bn }}</b-td>
                            <b-td>{{ currentLocale === 'en' ? item.district_name_en : item.district_name_bn }}</b-td>
                            <b-td>
                              <span class="badge badge-success" v-if="item.status == 1">{{$t('globalTrans.active')}}</span>
                              <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                            </b-td>
                          </b-tr>
                        </template>
                        <template v-else>
                          <b-tr>
                            <b-td align="center" colspan="8">{{ $t('globalTrans.noDataFound') }}</b-td>
                          </b-tr>
                        </template>
                      </b-tbody>
                    </b-table-simple>
                  </b-col>
                </b-row>
              </template>
            </body-card>
      </b-overlay>
    </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
export default {
    name: 'BotLeafList',
    props: ['masterMadeTeaTypeList'],
    data () {
      return {
        teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        loading: false,
        listData: []
      }
    },
    async created () {
      await this.loadData()
    },
    computed: {
      currentLocale () {
        return this.$i18n.locale
      }
    },
    methods: {
      async loadData () {
        const params = { show_all: 1 }
        this.loading = true
        const result = await RestApi.getData(teaGardenServiceBaseUrl, '/configuration/bought-leaf-factory/list', params)
        if (result.success) {
          this.listData = result.data.map((item, index) => {
            const divisionObj = this.$store.state.CommonService.commonObj.divisionList.find(division => division.value === parseInt(item.division_id))
            const districtObj = this.$store.state.CommonService.commonObj.districtList.find(district => district.value === parseInt(item.district_id))
            const divisionData = {}
            const districtData = {}
            if (typeof divisionObj !== 'undefined') {
                divisionData.division_name_en = divisionObj.text_en
                divisionData.division_name_bn = divisionObj.text_bn
            } else {
                divisionData.division_name_en = ''
                divisionData.division_name_bn = ''
            }
            if (typeof districtObj !== 'undefined') {
                districtData.district_name_en = districtObj.text_en
                districtData.district_name_bn = districtObj.text_bn
            } else {
                districtData.district_name_en = ''
                districtData.district_name_bn = ''
            }
            return Object.assign({}, item, { serial: index }, divisionData, districtData)
          })
        } else {
          this.listData = []
        }
        this.loading = false
      },
      getCompanyName (id) {
        const obj = this.masterTeaCompanyList.find(item => item.value === parseInt(id))
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getMadeTypeNames (data) {
        var madeTeaTypes = []
          if (Array.isArray(data)) {
            data.forEach(arrItem => {
            const item = this.masterMadeTeaTypeList.find(item => item.value === arrItem)
            if (item && this.$i18n.locale === 'bn') {
              madeTeaTypes.push(item.text_bn)
            } else if (item && this.$i18n.locale === 'en') {
              madeTeaTypes.push(item.text_en)
            }
            })
        }
        return madeTeaTypes.join(', ')
      }
    }
}
</script>
