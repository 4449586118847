<template>
    <div>
      <b-overlay :show="loading">
        <body-card>
              <template v-slot:body>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-table-simple small hover responsive bordered>
                      <b-thead>
                        <b-tr>
                          <b-th width="12%">{{ $t('globalTrans.sl_no') }}</b-th>
                          <b-th>{{ $t('globalTrans.application_id') }}</b-th>
                          <b-th>{{ $t('exportTrophy.applicant_name_address') }}</b-th>
                          <b-th>{{ $t('exportTrophy.org_name_address') }}</b-th>
                          <b-th>{{ $t('globalTrans.mobile') }}</b-th>
                          <b-th>{{ $t('globalTrans.email') }}</b-th>
                          <b-th>{{ $t('exportTrophy.export_income_proposed_income') }}</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <template v-if="listData.length">
                          <b-tr v-for="(item, index) in listData" :key="index">
                            <b-td>{{ $n(index + 1) }}</b-td>
                            <b-td>{{ $n(item.application_id, { useGrouping: false }) }}</b-td>
                            <b-td>
                              {{ currentLocale === 'bn' ? item.name_bn : item.name_en }} <br/>
                              {{ item.address ? AddressHelper.getPresentAddress(item.address) : '' }}
                            </b-td>
                            <b-td>
                              {{ item.org_info ? (currentLocale === 'bn' ? item.org_info.org_name_bn : item.org_info.org_name_en) : '' }} <br/>
                              {{ item.org_address ? AddressHelper.getCommonAddress(item.org_address) : '' }}
                            </b-td>
                            <b-td>{{ item.mobile | mobileNumber }}</b-td>
                            <b-td>{{ item.email }}</b-td>
                            <b-td>{{ $n(item.export_value, { useGrouping: false }) }}</b-td>
                          </b-tr>
                        </template>
                        <template v-else>
                          <b-tr>
                            <b-td align="center" colspan="7">{{ $t('globalTrans.noDataFound') }}</b-td>
                          </b-tr>
                        </template>
                      </b-tbody>
                    </b-table-simple>
                  </b-col>
                </b-row>
              </template>
            </body-card>
      </b-overlay>
    </div>
</template>
<script>
import RestApi, { exportTrophyCIPServiceBaseUrl } from '@/config/api_config'
import AddressHelper from '@/utils/area-type-address'
export default {
    props: ['search'],
    data () {
      return {
        AddressHelper: AddressHelper,
        exportTrophyCIPServiceBaseUrl: exportTrophyCIPServiceBaseUrl,
        loading: false,
        listData: []
      }
    },
    async created () {
      await this.loadData()
    },
    computed: {
      currentLocale () {
        return this.$i18n.locale
      }
    },
    methods: {
      async loadData () {
        this.loading = true
        const params = { year: this.search.year }
        const result = await RestApi.getData(exportTrophyCIPServiceBaseUrl, '/cip/primary-sorting/application-list', params)
        if (result.success) {
          this.listData = result.data
        } else {
          this.listData = []
        }
        this.loading = false
      }
    }
}
</script>
