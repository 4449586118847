<template>
  <div>
  <b-row>
    <b-col lg="12" sm="12">
        <b-row>
          <b-col lg="12" sm="12">
            <table style="width:100%">
              <thead>
                <tr>
                  <th class="text-center">{{ $t('globalTrans.sl_no') }}</th>
                  <th class="text-center">{{ $t('tradeFairProposalManagement.name_and_title') }}</th>
                  <th class="text-center">{{ $t('tradeFairProposalManagement.city_country') }}</th>
                  <th class="text-center">{{ $t('externalTradeFair.duration') }}</th>
                </tr>
              </thead>
              <tbody v-if="finalList.length === undefined">
                <tr>
                  <td class="text-center"> {{ (currentLocale === 'bn' ? 'o' : '0') + $n(1) }} </td>
                  <td class="text-center"> {{ (currentLocale === 'bn' ? 'o' : '0') + $n(2) }} </td>
                  <td class="text-center"> {{ (currentLocale === 'bn' ? 'o' : '0') + $n(3) }} </td>
                  <td class="text-center"> {{ (currentLocale === 'bn' ? 'o' : '0') + $n(4) }} </td>
                </tr>
                <span hidden>{{ $i = 1 }} {{ $si = 1 }}</span>
                <template v-for="(items, index) in finalList">
                  <tr style="background: #ddd;" :key="index">
                    <td colspan="4"> <span class="mr-3">{{ $n($i++) + '.' }}</span>{{ getFairSector(index) }} ({{ $n(items.length) }}) </td>
                  </tr>
                    <tr v-for="(item, index2) in items" :key="index2+index">
                      <td class="text-center"> {{$n($si++)}}</td>
                      <td> {{ currentLocale === 'bn' ? item.fair_name_bn : item.fair_name }}</td>
                      <td> {{ currentLocale === 'bn' ? item.city_bn : item.city }}, {{ getCountry(item.country_id) }}</td>
                      <td> {{ dateData(item.date_from) }} <b> {{ (currentLocale === 'bn' ? 'হতে' : 'to') }} </b> {{ dateData(item.date_to) }}</td>
                    </tr>
                </template>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="4" class="text-center"> {{ $t('fair_report.not_found') }} </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="text-right mt-1">
          <b-col> <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-final')">{{ $t('globalTrans.cancel') }}</b-button></b-col>
        </b-row>
    </b-col>
  </b-row>
  </div>
</template>
<script>
import { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import moment from 'moment'
export default {
  name: 'Details',
  props: ['finalList', 'fairSectorList'],
  components: {},
  data () {
    return {
      baseUrl: internationalTradeFairServiceBaseUrl
    }
  },
  created () {
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    }
  },
  watch: {
  },
  methods: {
    dateData (fdate) {
      require('moment/min/locales.min')
      moment.locale(this.$i18n.locale)
      const date = moment(fdate).format('Do MMMM, YYYY')
      return date
    },
    getDuration (dateFrom, dateTo) {
        const start = moment(dateFrom, 'YYYY-MM-DD')
        const end = moment(dateTo, 'YYYY-MM-DD')
        const numberOfDays = end.diff(start, 'days')
        if (numberOfDays > 0) {
          const years = Math.floor(numberOfDays / 365)
          const months = Math.floor(numberOfDays % 365 / 30)
          const days = Math.floor(numberOfDays % 365 % 30)

          const yearsDisplay = years > 0 ? this.currentLocale === 'bn' ? this.$n(years) + ' বছর ' : years + (years === 1 ? ' year, ' : ' years, ') : ''
          const monthsDisplay = months > 0 ? this.currentLocale === 'bn' ? this.$n(months) + ' মাস ' : months + (months === 1 ? ' month, ' : ' months, ') : ''
          const daysDisplay = days > 0 ? this.currentLocale === 'bn' ? this.$n(days) + ' দিন ' : days + (days === 1 ? ' day' : ' days') : ''
          return yearsDisplay + monthsDisplay + daysDisplay
        } else {
          return 0
        }
    },
    getFairSector (sector) {
      const sectorObj = this.fairSectorList.find(el => el.value === parseInt(sector))
      if (sectorObj !== undefined) {
        return this.currentLocale === 'bn' ? sectorObj.text_bn : sectorObj.text_en
      }
    },
    getCountry (countryId) {
      const countryObj = this.$store.state.CommonService.commonObj.countryList.find(el => el.value === parseInt(countryId))
      if (countryObj !== undefined) {
        return this.currentLocale === 'bn' ? countryObj.text_bn : countryObj.text_en
      }
    }
  }
}
</script>
<style scoped>
  table, th, td {
    border: 1px solid black;
    padding:5px;
  }
</style>
