<template>
  <!-- <div :style="{ background: `${item.backgroundColor} url('${backgroundImage}') right bottom / cover no-repeat`, }" class="price_card"></div> -->
  <div
    :style="{
      background: `${item.backgroundColor}`,
    }"
    class="price_card"
  >
    <div>
      <div class="title">
        <h1>{{ $i18n.locale === 'bn' ? item.commodity_name_bn : item.commodity_name_en }}</h1>
        <small class="text-muted">{{ $i18n.locale === 'bn' ? item.unit_name_bn : item.unit_name }}</small>
      </div>
      <div class="price">
        <p>{{ $t('ministry_dashboard.retail_price') }}: ৳
          {{ $n(item.lowest_price_amount) }} -
          {{ $n(item.highest_price_amount) }}
        </p>
      </div>
    </div>
    <div>
      <div class="img">
         <img :src="bazarMonitoringServiceBaseUrl + 'storage/' + item.commodity_picture" style="width: 75px; height: 75px">
        <!-- <img class="img-fluid" :src="item.image" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { bazarMonitoringServiceBaseUrl } from '@/config/api_config'

export default {
  data () {
    return {
      cardBgImg: '../assets/imgs/card-bg.svg',
      cod: '',
      bazarMonitoringServiceBaseUrl: bazarMonitoringServiceBaseUrl,
      check: ''
    }
  },
  props: ['item', 'backgroundImage'],
  created () {
  },
  computed: {
  }
}
</script>

<style scoped>
.price_card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  padding: 12px;
  min-height: 105px;
  background: url(../assets/imgs/card-bg.svg);
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: cover;
  .title {
    h1 {
      font-weight: bold;
      font-size: 1rem;
    }
    small {
      font-size: 0.7rem;
    }
  }
  .price {
    margin-top: 0.8rem;
    p {
      font-size: 0.8em;
      font-weight: bold;
    }
  }
  .img {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 40px;
    border: 2px solid #fff;
    background-color: #fff;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    img {
      margin: 50% auto;
      transform: translateY(-50%);
    }
  }
}
</style>
